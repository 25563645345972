.unverified-filter-h2 {
 color: var(--light-dark);
}

.unVerifiedfilter__inputs {
 background-color: var(--white);
 padding: 2rem;
 border-radius: 1rem;
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: flex-start;
 gap: 1rem;
 margin-bottom: 1rem;
 overflow: scroll;
 /* align-items: center; */
}

.tableContainer--unverified {
 width: 95%;
 margin-top: 3rem;
 margin-right: 2rem;
 background-color: var(--white);
 border-radius: 1rem;
 box-shadow: var(--box-shadow1);
 overflow-y: auto;
 overflow-x: auto;
}

/* .container {
 height: 100% !important;
} */

/* .filter__input--top {
	width: 100%;
} */
@media only screen and (max-width: 991px) {
 .filter__content {
  margin-right: 0;
 }
 .filter__content--top1,
 .filter__input--labels {
  width: 100%;
 }
 .filter__input {
  width: 100%;
 }
}
@media only screen and (max-width: 900px) {
 .invite-class {
  display: block;
 }
 .filter__content2 {
  width: 100%;
 }
 .filter__content1 {
  margin-right: 0;
  margin-bottom: 20px;
 }
}
@media only screen and (max-width: 767px) {
 /* .navigation {
  left: 0;
 }
 .navigation.active {
  width: 6rem;
 } */
 .filter__input--labels,
 .filter__input {
  width: 100%;
 }
}
@media only screen and (max-width: 575px) {
}
@media only screen and (max-width: 480px) {
 .filter__content1 {
  padding: 2rem 1rem;
  display: block;
 }
 .filter__content2 {
  padding: 2rem 1rem;
 }
 .email-fomt-size {
  font-size: 14px;
 }
 .pending-header {
  font-size: 18px;
 }
 .filter__content,
 .filter__content--unverified {
  padding: 2rem 1rem !important;
 }
}

@media only screen and (max-width: 900px) {
 .filter__content3 {
  margin-right: 0 !important;
  margin-bottom: 20px;
 }
}
