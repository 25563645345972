.flex_column_button {
 display: flex;
 flex-direction: column;
 gap: 2rem;
 align-items: flex-start;
}

.videoContainer {
 display: flex;
 justify-content: center;
 align-items: center;
}
