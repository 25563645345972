.ProviderFilter {
 display: flex;
 flex-direction: column;
 gap: 3rem;
 align-items: flex-start;
 transition: all 0.5s ease;
 overflow: hidden;
}
.ProviderFilter__inputs {
 display: flex;
 gap: 2rem;
}

.Providerfilter__content--top {
 display: flex;
 gap: 1rem;
}
.Providerfilter__content--top2 {
 display: flex;
 flex-wrap: wrap;
 align-items: flex-end;
 gap: 1rem;
}
