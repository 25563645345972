.container {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 2rem;
  /* align-items: start; */
}

.notification_left,
.notification_right {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  background-color: var(--white);
  padding: 2rem 2rem;
  border-radius: 2rem;
}

.notification_left {
  gap: 1.5rem;
}

.notification_right {
  gap: 0.5rem;
}

.notification_pagination {
  grid-column: 1/-1;
  grid-row: 2/3;
  display: flex;
  justify-content: flex-end;
}

.notification_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
}

.textBox {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.textBox_label {
  font-size: 1.2rem;
  color: var(--light-dark);
}

.textBox_error {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--red);
}

.textBox_input,
.textBox_input_box {
  background-color: var(--white);
  border: 1px solid var(--primary-color);
  padding: 1rem;
  box-shadow: var(--box-shadow-input);
  border-radius: 1rem;
  outline: none;
  font-family: inherit;
}

.textArea_input {
  background-color: var(--white);
  border: none;
  padding: 1rem;
  box-shadow: var(--box-shadow-input);
  border-radius: 1rem;
  outline: none;
  font-family: inherit;
}

.textBox_input_select {
  border: none;
  font-family: inherit;
  outline: none;
  background-color: transparent;
  width: 100%;
}

.notification_button {
  align-self: flex-start;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  color: var(--white);
  font-family: inherit;
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.notification_button_icon {
  height: 1.8rem;
  width: 1.8rem;
  fill: var(--white);
  transition: all 0.4s;
}

.notification_button:hover {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.notification_button:hover .notification_button_icon {
  fill: var(--primary-color);
}

.notification_button:disabled {
  background-color: transparent !important;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.notification_item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding-bottom: 0.5rem;
}

.notification_item_heading {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: capitalize;
}

.notification_item_desc {
  font-size: 1.1rem;
  font-weight: 400;
}

.notification_item_user {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--dark-color);
  margin-bottom: 0.5rem;

  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  /* flex-direction: column; */
}

.notification_item_user span {
  color: var(--primary-color);
  cursor: pointer;
}

.notification_item_date {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--primary-color);
  text-align: end;
}

.notification_buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}



.notification_delete_button {
  align-self: flex-start;
  background-color: var(--red);
  border: 1px solid var(--red);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  color: var(--white);
  font-family: inherit;
  font-weight: 500;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.4s; 
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
 
.notification_delete_button_icon {
  height: 1.8rem;
  width: 1.8rem;
  fill: var(--white);
  transition: all 0.4s;
}

.notification_delete_button:hover {
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.notification_delete_button:hover .notification_delete_button_icon {
  fill: var(--red);
}

.notification_delete_button:disabled {
  background-color: transparent !important;
  border: 1px solid var(--red);
  color: var(--red);
}

.notification_delete_button:disabled .notification_delete_button_icon{
  fill: var(--red);
}