.dashboard {
 width: 100%;
 display: flex;
 flex-direction: column;
 gap: 2rem;
 align-items: flex-start;
 padding-bottom: 3.5rem;
}
.dashboard__heading {
 font-size: 1.5rem;
 font-weight: 500;
 color: var(--primary-color);
}
.dashboard__options {
 display: flex;
 flex-wrap: wrap;
 gap: 2rem;
 /* align-items: flex-start; */
}
.dashboard__option {
 display: flex;
 flex-direction: column;
 gap: 2rem;
 align-items: flex-start;
 background-color: var(--white);
 padding: 2rem;

 box-shadow: var(--box-shadow1);
 border-radius: 3rem;
 position: relative;
 min-width: 28rem;
}
.dashboard__option--logo {
 width: 15rem;
}
.dashboard__option--heading {
 font-size: 1.7rem;
 font-weight: 600;
}
.dashboard__option--provider {
 font-size: 1.4rem;
 font-weight: 500;
 font-style: italic;
 color: var(--primary-color);
}
.dashboard__option--link {
 position: relative;
 /* bottom: -25%; */
 /* left: 50%; */
 /* transform: translate(-50%, -50%); */

 text-decoration: none;
 outline: none;
 background-color: var(--primary-color);
 padding: 1rem 3rem;
 border-radius: 10rem;
 color: var(--white);

 display: flex;
 align-items: center;
}
.dashboard__option--icon {
 height: 2.5rem;
 width: 2.5rem;
 fill: var(--white);
}

@media (max-width: 1024px) {
 .dashboard {
  justify-content: center;
  align-items: center;
 }
 .dashboard__options {
  gap: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
 }
}
@media (max-width: 767px) {
 .dashboard {
  justify-content: center;
  align-items: center;
 }
 .dashboard__options {
  gap: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
}
