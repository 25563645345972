.loginContainer {
    display: grid;
    grid-template-columns: 5rem repeat(2, 1fr) 5rem;
    height: 100vh;
    align-items: center;
}

.loginContainer__bg {
    background-color: var(--primary-color);
    height: 100vh;
    width: 50vw;
}

.loginContainer__box {
    grid-column: 2 / -2;
    height: 80vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    box-shadow: var(--box-shadow1);
    border-radius: 1rem;
    overflow: hidden;
}

.loginContainer__box--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.loginContainer__box--logo {
    /* height: 12rem; */
    height: 5rem;
}

.loginContainer__heading {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 400;
}

.loginContainer__subheading {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 2rem;
}

.loginContainer__box--left {
    margin-top: 2rem;
    /* margin-bottom: 10rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    padding-right: 2rem;
}

.loginContainer__box--image {
    margin: 3rem;
    align-self: flex-end;
    height: 4rem;
}

.loginContainer__form {
    margin-top: 2rem;
    margin-left: 3rem;
}

.loginContainer__form--heading {
    font-size: 2rem;
    font-weight: 100;
    color: var(--primary-color);
}

.loginContainer__formgroup {
    margin: 2rem 0;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
}

.loginContainer__formgroup--label {
    font-size: 1.3rem;
    font-weight: 500;
}

.loginContainer__formgroup--inputBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 10rem;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    overflow: hidden;
    width: 22vw;
}

.logoContainer__formgroup--iconBox {
    padding: 1.5rem 2rem;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
}

.loginContainer__formgroup--icon {
    height: 2rem;
    width: 2rem;
    fill: var(--white);
}

.loginContainer__formgroup--input {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: inherit;
    width: 100%;
}

.loginContainer__formgroup--forgotPassword {
    width: 22vw;
    display: flex;
    justify-content: flex-end;
}

.loginContainer__formgroup--forgotPasswordBtn {
    text-decoration: none;
    color: currentColor;
    font-size: 1.2rem;
    margin-top: 1rem;
    transition: all 0.4s;
}

.loginContainer__formgroup--forgotPasswordBtn:hover {
    color: var(--primary-color);
    transform: scale(1.05);
}

.loginContainer__formgroup--btn {
    /* width: 22vw; */
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
}

.loginContainer__button {
    margin-top: 1.5rem;
    font-family: inherit;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding-left: 2rem;
    transition: all 1s;
}

.loginContainer__button:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

.loginContainer__button:hover .loginContainer__button--iconBox {
    background-color: var(--bg-primary);
}

.loginContainer__button:hover .loginContainer__button--icon {
    fill: var(--primary-color);
}

.loginContainer__button--iconBox {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 50%;
    transition: all 1s;
}

.loginContainer__button--icon {
    fill: var(--white);
    height: 3rem;
    width: 3rem;
    transition: all 1s;
}

.loginContainer__button--text {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}

.loginError {
    width: 22vw;
    font-weight: 500;
    font-size: 1.3rem;
}

@media (max-width: 1024px) {
    .loginContainer {
        display: grid;
        /* grid-template-columns: 1rem repeat(2, 1fr) 5rem; */
        height: 100vh;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .loginError {
        width: 100%;
    }

    .loginContainer {
        display: flex;
        /* grid-template-columns: 1rem repeat(2, 1fr) 5rem; */
        height: 100%;
        /* align-items: center; */
    }
    .loginContainer__subheading{
        margin-top: 1rem;
    }
    .loginContainer__formgroup{
        padding: 0rem 1rem;
    }
    .loginContainer__bg {
        position: absolute;
        z-index: -1;
        top: 0;
        /* background-color: var(--bg-primary); */
        background-color: var(--primary-color);
        height: 30%;
        width: 100%;
    }

    .loginContainer__box {
        margin: 3rem;
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 75vh;
    }

    .loginContainer__box--right {
        margin-top: 1rem;
        /* color: rgb(0, 0, 0); */
    }

    .loginContainer__box--left {
        margin-top: 0;
        padding: 1rem;
    }

    .loginContainer__box--logo {
        height: 3rem;
    }

    .loginContainer__box--image {
        display: none;
    }

    .loginContainer__form {
        width: 100%;
        margin: 0;
        overflow: auto;
        /* s */
    }

    .loginContainer__button {
        margin-top: 0;
    }

    .loginContainer__formgroup--inputBox {
        width: 100%;
    }

    .loginContainer__formgroup--btn {
        width: 100%;
        justify-content: center;
    }

    .loginContainer__formgroup--forgotPassword {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}