.manageClinic__filter__main {
 gap: 2rem;
 display: flex;
 align-items: center;
}

.manageClinic__Clinic-enable__content {
 background-color: var(--white);
 border-radius: 1rem;
 padding: 2rem;
}
.activateToggle--p {
 font-size: 12px;
}
.activateToggle-span {
 color: var(--primary-color);
 font-weight: bold;
}
