.navigation {
    grid-column: 1 / 2;
    padding-top: 2rem;
    position: fixed;
    width: 20rem;
    background-color: var(--white);
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navigation.active {
    width: 5.5rem;
    transition: all 0.5s ease;
}

.navigation__logo {
    /* height: 4rem; */
    height: 1.8rem;
    padding-left: 2rem;
}

.navigation__logo2 {
    height: 4rem;
    padding-left: 2rem;
    display: none;
}

.sidebarnav__button {
    display: none;
}

.sidebarnav__icon {
    height: 2.5rem;
    width: 2.5rem;
    fill: var(--primary-color);
}

.navigation__list {
    margin-top: 3rem;
    width: 100%;
    padding-left: 0px;
    list-style: none;
}

.activeNav {
    margin-left: 1rem;
    height: 2rem;
    width: 0.5rem;
    background-color: var(--primary-color);
}

.currentNav {
    display: flex;
    align-items: center;
}

.currentNav .navigation__list--itemHover .navigation__list--link {
    color: var(--dark-color);
}

.currentNav .navigation__list--itemHover .navigation__list--link .navigation__icon {
    fill: var(--primary-color);
}

.navigation__list--item {
    padding: 1rem 0;
    padding-left: 2rem;
    display: none;
    transition: all 0.4s;
}

.navigation__list--copyright {
    padding: 1rem 0;
    padding-left: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    width: 20rem;
}

.navigation__list--copyrightHidden {
    padding: 1rem 0;
    padding-left: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    display: none;
}

.navigation__list--itemHover {
    width: 20rem;
    padding: 1rem 0;
    padding-left: 2rem;
    transition: all 0.4s;
    /* transform: translateX(-20rem); */
    display: block;
    cursor: pointer;
}

.navigation__list--itemHover:hover,
.navigation__list--item:hover {
    background: var(--bg-primary);
    transform: translateX(0.2rem);
}

.navigation.active .navigation__list--itemHover {
    display: none;
}

.navigation.active .navigation__list--item {
    display: block;
}

.navigation.active .navigation__list--copyright {
    display: none;
}

.navigation.active .navigation__list--copyrightHidden {
    display: block;
}

.navigation.active .navigation__logo {
    display: none;
}

.navigation.active .navigation__logo2 {
    display: block;
}

.navigation__list--link {
    text-decoration: none;
    color: var(--light-dark);
    font-size: 1.2rem;
    font-weight: 500;

    display: flex;
    gap: 1rem;
}

.navigation__list--link2 {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 1.2rem;
    font-weight: 500;

    display: flex;
    gap: 1rem;
}

.navigation__icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--light-dark);
}

.navigation__icon2 {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--primary-color);
}

.hasChildren {
    position: relative;
}

/* .hasChildren::after {
 cursor: pointer;
 padding-right: 1rem;
 content: " \002B";
 color: var(--dark-grey);
 font-size: 1.7rem;
 position: absolute;
 right: 0rem;
 top: 0.7rem;
} */
/* .subMenuActive {
	background-color: var(--primary-color);
} */
/* .subMenuActive.hasChildren::after {
 content: " \002D";
} */
.subMenuActive.hasChildren ul {
    display: block;
}

.navigation__sub__active {
    /* color: var(--black) !important;
 font-weight: 600 !important; */
}

.hasChildren ul {
    margin-top: 1rem;
    display: none;
    list-style: none;
}

.hasChildren ul li {
    padding: 0.5rem 1rem;
    transition: all 0.4s;
}

.hasChildren ul li:hover {
    transform: scale(1.1);
}

.hasChildren ul li a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    text-align: center;
}

.hasChildren ul li a svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: var(--dark-grey);
}

@media (max-width: 1024px) {
    .navigation {
        display: none;
    }

    .navigation.active {
        grid-column: 1 / 2;
        padding-top: 2rem;
        position: fixed;
        width: 20rem;
        background-color: var(--white);
        height: 100vh;
        overflow: hidden;
        z-index: 1000;
        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navigation__logo--container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sidebarnav__button {
        margin-top: 1rem;
        display: block;
        margin-left: 2rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .navigation.active .navigation__list--item {
        display: none;
    }

    .navigation.active .navigation__list--itemHover {
        display: block;
    }

    .navigation.active .navigation__logo {
        display: block;
    }

    .navigation.active .navigation__logo2 {
        display: none;
    }
}

@media (max-width: 767px) {}