.skeleton {
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}
.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 241, 0.1),
    rgba(231, 231, 234, 0.7),
    rgba(231, 231, 234, 0.1)
  );
  animation: progress 1s ease-in-out infinite;
}
@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.pending__email--skeleton {
  height: 2rem;
  width: 30rem;
  background-color: var(--skeleton);
}
.pending__button--skeleton {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: var(--skeleton-red);
}
.filter__input--label-skeleton {
  height: 2rem;
  width: 10rem;
  background-color: var(--skeleton);
}
.filter__input--label2-skeleton {
  height: 2rem;
  width: 10rem;
  background-color: var(--skeleton);
  margin-left: 90rem;
}
.filter__input--label3-skeleton {
  height: 2rem;
  width: 20rem;
  background-color: var(--skeleton);
  margin-left: 100rem;
}
.filter__input--label1-skeleton {
  height: 2rem;
  width: 50rem;
  background-color: var(--skeleton);
}
.filter__input--labellong-skeleton {
  height: 2rem;
  width: 90%;
  background-color: var(--skeleton);
}

.filter__input--labellong1-skeleton {
  height: 2rem;
  width: 95%;
  background-color: var(--skeleton);
  /* margin-left: 2rem; */
}
.client__input--labels {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.filter__input--textBox-skeleton {
  height: 4rem;
  width: 15rem;
  background-color: var(--skeleton);
}
.filter__input--textBoxlong-skeleton {
  height: 4rem;
  width: 30rem;
  background-color: var(--skeleton);
}
.filter__input--buttons-skeleton {
  margin-top: 1rem;
}
.filter__input--button-skeleton {
  height: 3.5rem;
  width: 10rem;
  background-color: var(--skeleton-blue);
}

.client__input--button-skeleton {
  top: 22.5rem;
  position: absolute;
  height: 3.5rem;
  width: 10rem;
  background-color: var(--skeleton-blue);
}
.dashboard__input--button-skeleton {
  top: 27rem;
  position: absolute;
  height: 3.5rem;
  width: 10rem;
  background-color: var(--skeleton-blue);
}

.login__input--button-skeleton {
  border-radius: 22px;
  height: 4rem;
  width: 12rem;
  background-color: var(--skeleton-blue);
}
.modal__text--skeleton {
  margin-bottom: 1rem;
  height: 1.5rem;
  width: 10rem;
  background-color: var(--skeleton);
}

.modal__text1--skeleton {
  height: 1.5rem;
  width: 30rem;
  background-color: var(--skeleton);
}
.filter__input--skeleton {
  height: 3.5rem;
  width: 25rem;
  background-color: var(--skeleton);
}
.clientContainer__form {
  display: flex;
}

.client__input--skeleton {
  height: 15rem;
  width: 25rem;
  background-color: var(--skeleton);
}

.login__input--skeleton {
  border-radius: 22px;
  height: 3.5rem;
  width: 25rem;
  background-color: var(--skeleton);
}
.filter__input--skeleton {
  background-color: var(--skeleton);
}
.filter__input--email-skeleton {
  height: 4rem;
  width: 95%;
  background-color: var(--skeleton);
}
.modal__button--cancel-skeleton {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--skeleton-red);
  margin-left: 23rem;
}
.modal__button--delete-skeleton {
  height: 3.5rem;
  width: 10rem;
  background-color: var(--skeleton-red);
}
/* .modal__button--cancel-skeleton {
	background-color: var(--skeleton-blue);
} */
.modal__button--update-skeleton {
  background-color: var(--skeleton-blue);
  height: 3.5rem;
  width: 10rem;
}
.modal__button--update1-skeleton {
  background-color: var(--skeleton-blue);
  height: 3.5rem;
  width: 12rem;
  border-radius: 25rem;
}
.modal__button--update2-skeleton {
  background-color: var(--skeleton-blue);
  height: 3.5rem;
  width: 12rem;
  margin-left: 10rem;
  border-radius: 25rem;
}
.modal__button--update3-skeleton {
  background-color: var(--skeleton-blue);
  height: 3.5rem;
  width: 12rem;
  margin-left: 80rem;
  border-radius: 25rem;
}
.modal__button--toggle-skeleton {
  background-color: var(--skeleton-blue);
  height: 3rem;
  width: 5rem;
}
.modal__actions__background {
  background-color: var(--white);
  display: flex;
  gap: 0.5rem;
  padding: 2rem;
  padding-left: 2rem;
  flex-direction: column;
  border-radius: 2rem;
}

.resetPassword__button--delete-skeleton {
  height: 3rem;
  width: 20rem;
  background-color: var(--skeleton-red);
}

.table__row--skeleton {
  /* margin: 1rem 0; */
  height: 3.2rem;
  width: 100%;
  background-color: var(--skeleton);
}

.profileContainer__form {
  padding: 2rem;
}
.profile__input--labels {
  width: 100%;
}
.profile__input--skeleton {
  height: 3.5rem;
  width: 100%;
  background-color: var(--skeleton);
}
.profile--skeleton__button {
  float: left;
  padding: 1rem 0 0 0;
}

.scheduler-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content__input--skeleton {
  height: 4rem;
  width: 10rem;
  background-color: var(--skeleton);
}
.contents__input--skeleton {
  height: 10rem;
  width: 25rem;
  background-color: var(--skeleton);
}

.pdf_frame_register {
  height: calc(50vh - 4rem);
  min-width: 45vw;
  width: 100%;
  border-radius: 2rem;
  background-color: var(--white);
}
.card_pdf_frame {
  background-color: var(--bg-primary);
}
.card_pdf_frame_2 {
  background-color: var(--white);
}
.pdf_heading {
  height: 2rem;
  width: 20rem;
  background-color: var(--bg-primary);
}
.pdf_button {
  height: 2rem;
  width: 10rem;
  background-color: var(--bg-primary);
}
.pdf_heading_2 {
  height: 2rem;
  width: 20rem;
  background-color: var(--white);
}
.pdf_button_2 {
  height: 2rem;
  width: 10rem;
  background-color: var(--white);
}
.select_default_heading {
  height: 1.3rem;
  width: 30rem;
  background-color: var(--white);
}
.select_default_dropdown {
  height: 4rem;
  width: 15rem;
  background-color: var(--white);
}
.select_default_button {
  height: 4rem;
  width: 10rem;
  background-color: var(--skeleton-blue);
}
.card_upload_label {
  height: 1.2rem;
  width: 10rem;
  background-color: var(--white);
}
.card_upload_input {
  margin-top: 0.5rem;
  height: 2rem;
  width: 15rem;
  background-color: var(--white);
}
.card_upload_btn {
  height: 3rem;
  width: 10rem;
  background-color: var(--skeleton-blue);
}

/* Subscription */
.subscription_title_skeleton {
  height: 1.3rem;
  width: 10vw;
  background-color: var(--bg-primary);
}
.subscription_price_skeleton {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  height: 4rem;
  width: 10rem;
  background-color: var(--bg-primary);
}
.subscription_meta_skeleton {
  height: 1.5rem;
  /* width: 20rem; */
  background-color: var(--bg-primary);
}
.subscription_meta2_skeleton {
  height: 1.5rem;
  width: 5rem;
  background-color: var(--bg-primary);
}
.subscription_total_skeleton {
  margin-top: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.subscription_total_heading_skeleton {
  height: 1.3rem;
  width: 10rem;
  background-color: var(--bg-primary);
}
.subscription_total_subheading_skeleton {
  height: 1rem;
  width: 20rem;
  background-color: var(--bg-primary);
}
.outline__button_skeleton {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-family: inherit;
  border-radius: 15px;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  height: 47px;
  padding: 1rem 3rem;
}

/* subscription payment */
.item_title_skeleton {
  height: 1.5rem;
  width: 20rem;
  background-color: var(--bg-primary);
}
.item_description_skeleton {
  margin-top: 0.8rem;
  height: 1.3rem;
  width: 30vw;
  background-color: var(--bg-primary);
}
.payment_card_price_skeleton {
  height: 4rem;
  width: 8rem;
  background-color: var(--bg-primary);
}
.item_promo_skeleton {
  height: 2rem;
  width: 15rem;
  background-color: var(--skeleton-blue);
}
.item_promo_plus_skeleton {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--skeleton-blue);
}
.dis_item_title_skeleton {
  height: 1.5rem;
  width: 10rem;
  background-color: var(--bg-primary);
}
.dis_price_skeleton {
  height: 1.5rem;
  width: 4rem;
  background-color: var(--bg-primary);
}
.order_summary_total_skeleton {
  height: 1.5rem;
  width: 18rem;
  background-color: var(--bg-primary);
}
.order_summary_total_price_skeleton {
  height: 4rem;
  width: 8rem;
  background-color: var(--bg-primary);
}
.stripePayment__formgroup_label_skeleton {
  height: 1.3rem;
  width: 5vw;
  background-color: var(--bg-primary);
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .dashboard__input--button-skeleton {
    top: 29.5rem;
  }
}

@media (max-width: 767px) {
  .clientContainer__form {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  .dashboard__input--button-skeleton {
    top: 26rem;
  }
}
