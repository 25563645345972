.container {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(6rem, 20rem) repeat(1, 1fr);
  height: 100vh;
  transition: all 0.5s ease;
}

.mainContent {
  grid-column: 2 / -1;
  /* overflow: hidden; */
  transition: all 0.5s ease;
  position: absolute;
  left: 20rem;
  top: 0;
  right: 0;
  bottom: 0;
}
.mainContent__active {
  transition: all 0.5s ease;
  left: 6rem;
}
.content {
  margin-top: 4.5rem;
  height: 85vh;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  padding-left: 4rem;
  padding-right: 4rem;
}

.content2 {
  margin-top: 4.5rem;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 4rem;
  margin-right: 4rem;
}
.content3 {
  margin-top: 4.5rem;
  height: 85vh;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  padding-left: 4rem;
  padding-right: 4rem;
}
.tableContainer--paginater {
  padding-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
}
.tableContainer {
  width: 100%;
  margin-top: 2rem;
  margin-right: 5rem;
  /* margin-left: 2rem; */
  margin-bottom: 2.5rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow1);
  height: 35vh;
  overflow-y: auto;
  overflow-x: auto;
  /* transition: all .4s; */
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
th {
  position: sticky;
  top: 0;
  z-index: 100;
}
tr:nth-child(even) {
  background-color: var(--bg-primary);
}
.table tr th {
  white-space: nowrap;
  background-color: var(--table-heading);
  color: var(--dark-color);
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left; /*remove for center alignement */
}

.table tr td {
  white-space: nowrap;
  padding: 1rem;
  color: var(--light-dark);
  text-align: left;
}
.table tr {
  transition: all 0.8s;
  border-bottom: 1px solid var(--placeholder);
  /* text-align: center; */
  font-size: 1.2rem;
}
.table__icon {
  height: 1.5rem;
  width: 1.5rem;
}
.table__iconBox {
  display: flex;
  align-items: left; /*change this to center*/
  justify-content: left; /*change this to center*/
  gap: 0.5rem;
}
.table__btn {
  text-decoration: none;
  border: none;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  outline: none;
  font-family: inherit;
  color: var(--white);
  padding: 0.2rem 1rem;
  font-size: 1rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: all 0.4s;
}
.table__btn:hover {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.table__button--verify {
  border: none;
  font-family: inherit;
  color: var(--primary-color);
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.4s;
}
.table__button--verify span {
  font-size: 1.4rem;
  padding-left: 1rem;
  font-weight: 500;
}
.table__button--verifyIconContainer {
  background-color: var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.4s;

  display: flex;
  align-items: center;
}
.table__button--verifyIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--white);
}
.table__button--verify:hover {
  background-color: var(--primary-color);
  color: var(--white);
}
.table__button--verify:hover .table__button--verifyIconContainer {
  background-color: var(--white);
}
.table__button--verify:hover .table__button--verifyIcon {
  fill: var(--primary-color);
}
.table__button {
  border: none;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  outline: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  align-items: center;
  position: relative;
}
.table__button span,
.table__button2 span {
  display: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--dark-color);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -1.5rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}
.table__button:hover span,
.table__button2:hover span {
  display: block;
}
.table__button:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.table__button:hover .table__button--icon {
  fill: var(--white);
}
.table__button--icon {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--primary-color);
}
.table__buttonBig {
  border: none;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: inherit;
  position: relative;
}
.table__button--iconBig {
  height: 2rem;
  width: 2rem;
  fill: var(--primary-color);
}
.table__button--iconBigBlack {
  height: 2rem;
  width: 2rem;
  fill: var(--light-dark);
}
.table__button--iconBigDelete {
  height: 2rem;
  width: 2rem;
  fill: var(--red);
}
.table__buttonBig span {
  display: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--dark-color);
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  position: absolute;
  top: -1.5rem;
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  transition: all 1s;
  right: 1rem;
  z-index: 1000;
}
.table__buttonBig:hover span {
  display: block;
}
.table__button2 {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  align-items: center;
  position: relative;
}
.table__button--delete {
  border: none;
  background-color: transparent;
  border: 1px solid var(--red);
  outline: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  align-items: center;
}
.table__button--delete:hover {
  background-color: var(--red);
  border: 1px solid var(--red);
}
.table__button--delete:hover .table__button--icon-red {
  fill: var(--white);
}
.table__button--icon-red {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--red);
}

/* Toggle Button Start */
.switch {
  position: relative;
  display: inline-block;
  min-width: 5rem;
  max-width: 5rem;
  height: 3.05rem;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-dark);
  box-shadow: var(--box-shadow-input);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 2.3rem;
  width: 2.3rem;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: var(--primary-color);
}

.checkbox:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.checkbox:checked + .slider:before {
  -webkit-transform: translateX(1.8rem);
  -ms-transform: translateX(1.8rem);
  transform: translateX(1.8rem);
}

.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}
.error_message {
  color: var(--red);
  font-weight: 500;
  font-size: 1.3rem;
}
@media (max-width: 768px) {
  .tableContainer {
    width: 100%;
  }
  .error_message {
    color: var(--red);
    font-weight: 500;
    font-size: 1.3rem;
    width: 100%;
    text-align: center;
  }
  .tableContainer--paginater {
    padding: 1rem;
  }
  .paginationBttns {
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 0rem repeat(1, 1fr);
    overflow-x: hidden;
  }
  .mainContent {
    grid-column: span;
    margin-bottom: 2rem;
    left: 0;
  }
  .mainContent__active {
    left: 0;
    overflow: scroll;
  }
  .content {
    margin-top: 0;
    padding: 1rem;
  }
  .tableContainer {
    width: 100%;
  }
  .header {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (max-width: 385px) {
  .header {
    gap: 0;
    flex-wrap: wrap;
  }
}
/* Toggle Button End */

.paginationTop {
  margin-bottom: '-4rem';
}
.paginationActive {
  background-color: var(--primary-color);
}
.paginationActive a {
  color: var(--white) !important;
}
.paginationBttns {
  list-style: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.paginationBttns li {
  height: 4rem;
  width: 4rem;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0.4rem 1.5rem 0.5rem rgb(0 0 0 / 16%);
  transition: all 0.4s;
  overflow: hidden;
}

.paginationBttns a {
  padding: 2rem;
  /* margin: .8rem; */
  /* border: 1px solid var(--primary-color); */
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s;
}

.paginationBttns li:hover {
  color: var(--white);
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.paginationBttns a:hover {
  color: var(--white);
}
.break {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 2rem !important;
}
.break a:hover {
  color: var(--primary-color) !important;
}

.paginationDisabled a {
  color: var(--white);
  background-color: var(--light-dark);
  border: 1px solid var(--light-dark);
}
.paginationDisabled a:hover {
  background-color: var(--light-dark);
  border: 1px solid var(--light-dark);
  cursor: unset;
}
/************************************************ Error Message ***************************************/

.Toastify__toast-body {
  font-size: 1.5rem;
  /* color: var(--white); */
}

/* .Toastify__toast--success {
	background-color: var(--primary-color) !important;
}

.Toastify__toast--error {
	background-color: var(--red) !important;
} */

.react-datepicker-wrapper {
  width: 100%;
}

.Toastify__toast-container--bottom-right {
  z-index: 999999 !important;
}
.css-b62m3t-container {
  width: 100% !important;
  position: unset !important;
  margin-left: auto !important;
}
.css-13cymwt-control{
  border: 0 !important;
}