.content-table {
    border-collapse: collapse;
    margin: 25px 0;
    width: 90%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0.5rem 0.5rem 2rem 2px rgb(0 0 0 / 14%);;
  }
  
  .content-table thead tr {
    background-color: #E5E5E5;
    color: --dark-color;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }
  
  .content-table th,
  .content-table td {
    padding: 12px 15px;
  }
  
  .content-table tbody tr {
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    font-style: normal;
    font-weight: 500;
  }
  
  .content-table tbody tr:nth-of-type(even) {
    background-color: --bg-primary;
  }
  .error{
    font-size: initial;
    color: red;
    width: 75%;
  }

  /*--------Media Querries for tablet & Ipad pro------------*/
@media (min-width:500px) and (max-width:1200px) {
    .content-table {
    width: 85%;
}
}

@media (max-width:499px) {
    .content-table {
    width: 60%;
}
}
/*--------Media Querries for tablet & Ipad pro------------*/