.header {
  padding-top: 2rem;
  padding-right: 4rem;
  padding-left: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
}
.header_back {
  display: flex;
  cursor: pointer;
  margin-top: 0.4rem;
}
.header_add_partner {
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
  margin-top: 0.4rem;
}
.header_back__icon {
  margin-top: 0.4rem;
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--primary-color);
}
.header__left--container {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}
.header__left {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}
.header__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.header__icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--primary-color);
}

.header__container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.header__heading {
  font-size: 1.5rem;
  font-weight: 600;
}
.header__container--btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 2rem;
  width: 2.8rem;
  position: relative;
  transition: all 1s ease;
}
.header__container--btn p {
  display: flex;
  align-items: center;
}
.header__container--icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}
.header__container--btn span {
  font-weight: 600;
  color: var(--white);
  position: absolute;
  top: 0.5rem;
  left: 2.5rem;
  transition: opacity 1000ms;
  opacity: 0;
  white-space: nowrap;
  font-size: 0;
  width: 0;
  margin: 0;
}
.header__container--btn:hover {
  transition: all 400ms cubic-bezier(0.62, 0.1, 0.5, 1);
  border-radius: 1rem;
  width: 13.5rem;
}
.header__container--btn:hover span {
  font-size: 1.2rem;
  opacity: 1;
  width: auto;
  transition: opacity 700ms, width 1ms linear 270ms, font-size 1ms linear 270ms;
}
.header__image {
  height: 4rem;
}

.dropdown {
  z-index: 1;
  display: inline-block;
  position: relative;
}
.dropdown-a {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-a svg {
  height: 3rem;
  width: 3rem;
  fill: var(--dark-color);
}
.dropdown-a p {
  position: absolute;
  top: -1rem;
  right: -0.8rem;
  background-color: var(--primary-color);
  border: 3px solid var(--bg-primary);
  padding: 0.4rem;
  border-radius: 50%;
  color: var(--white);
  font-weight: 600;
  line-height: 1.1;
  font-size: 1.1rem;
  width: 2.4rem;
  height: 2.4rem;
  text-align: center;
}
.dropdown input:after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  display: block;
  background: #c63d0f;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition-duration: 0.2s;
  -webkit-transform: scaleX(0);
  -webkit-transform-origin: bottom left;
  -webkit-transition-duration: 0.2s;
}
.dropdown input {
  top: -1.5rem;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 3rem;
  width: 2.3rem;
}
.dropdown input:hover {
  cursor: pointer;
}
.dropdown input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dropdown input:checked ~ .dropdown-c {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dropdown-c {
  display: block;
  position: absolute;
  height: auto;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
}
.dropdown-c ul {
  margin: 0;
  margin-top: 1rem;
  padding: 1.5rem 0;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  list-style-type: none;
  background-color: var(--bg-primary);
  border-radius: 2rem;
  box-shadow: 0px 0px 1.5rem 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  width: 35rem;
}
.dropdown-c li {
  display: block;
  position: relative;
}
.dropdown-c .downdown-c_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 5px 2rem;
}
.dropdown-c .downdown-c_heading .downdown-c_head {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
}
.dropdown-c .downdown-c_heading button {
  font-family: inherit;
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--dark-color);
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.4s;
}
.dropdown-c .downdown-c_heading button:hover {
  border-bottom: 1px solid var(--primary-color);
  color: var(--primary-color);
  /* transform: scale(1.03); */
}
.dropdown_link {
  display: block;
  position: relative;
  text-decoration: none;
  color: var(--dark-color);
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;
  background-color: transparent;

  transition: all 0.4s;
}
.dropdown_link_btn {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  font-family: inherit;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.unread {
  font-weight: 600;
}
.dropdown_link_msg {
  display: block;
  font-size: 1.1rem;
}
.dropdown_link_time {
  display: block;
  font-size: 1rem;
  font-style: italic;
  text-align: right;
}
.dropdown_link:hover {
  transform: translateX(0.5rem);
}
.dropdown_show_more {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown_show_more button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--orange);
}

.notification-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.notification-icon{
  width: 4rem;
  height: 4rem;
}

.notification-box-msg{
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 767px) {
  .header {
    padding: 2rem;
  }
}
