.paymentPage {
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 4rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 36% 70%;
  grid-template-rows: 1fr 8rem;
}
.paymentContainer {
  grid-column: 2 / -1;
  grid-row: 1 / 2;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.stripePayment__button_layout {
  grid-column: 1 / -1;
  grid-row: 2/ -1;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.stripePayment__button {
  margin-top: 1.5rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
}

.stripePayment__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.stripePayment__button:hover .stripePayment__button_iconBox {
  background-color: var(--bg-primary);
}

.stripePayment__button:hover .stripePayment__button_icon {
  fill: var(--primary-color);
}

.stripePayment__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.stripePayment__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.stripePayment__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
}

.stripePayment__button:disabled {
  background-color: var(--white);
  color: #8e8b8b;
}
.stripePayment__button:disabled:hover {
  background-color: var(--white);
  color: #8e8b8b;
  cursor: not-allowed;
}
.stripePayment__button:disabled:hover .stripePayment__button_icon {
  fill: var(--white);
}

.stripePayment__button:disabled .stripePayment__button_iconBox {
  background: #8e8b8b;
}

.stripePayment__button:disabled .stripePayment__button_icon {
  fill: var(--white);
}

.stripePayment__form {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem 3rem;
}

.stripePayment__form_heading {
  font-size: 2rem;
  font-weight: 100;
  color: var(--primary-color);
}

.stripePayment__formgroup {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.stripePayment__formgroup_label {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
}

.stripePayment__formgroup_inputBox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 10rem;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  overflow: hidden;
  width: 100%;
  height: 5rem;
}

.stripePayment__formgroup_iconBox {
  padding: 1.5rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
}

.stripePayment__formgroup_icon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.stripePayment__formgroup_input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
}

.stripePayment__formgroup--btn {
  /* width: 22vw; */
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
}

.stripePayment__button {
  margin-top: 1.5rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
}

.stripePayment__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.stripePayment__button:hover .stripePayment__button_iconBox {
  background-color: var(_bg-primary);
}

.stripePayment__button:hover .stripePayment__button_icon {
  fill: var(--primary-color);
}

.stripePayment__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.stripePayment__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.stripePayment__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
}

.paymentCardContainer {
  width: 100%;
  transition: all 0.4s;
  display: grid;
  grid-gap: 2rem;
  column-gap: 1.5rem;
  row-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.stripePayment__formgroup1 {
  grid-column: 1/3;
  grid-row: 1/2;
}
.stripePayment__formgroup2 {
  grid-column: 3/-1;
  grid-row: 1/2;
}
.stripePayment__formgroup3 {
  grid-column: 1/2;
  grid-row: 2/-1;
}
.stripePayment__formgroup4 {
  grid-column: 2/3;
  grid-row: 2/-1;
}
.stripePayment__formgroup5 {
  grid-column: 3/-1;
  grid-row: 2/-1;
}

.paymentCardNumber {
  border: none;
  padding: 0rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}

.paymentCardLabel {
  font-size: 1.2rem;
  font-weight: 400;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s;
}

/* @media (max-width: 1450px) {
  .stripePayment__formgroup_iconBox {
    padding: 2rem 1.5rem;
  }
  .stripePayment__formgroup_icon {
    height: 1.5rem;
    width: 1.5rem;
  }
} */

@media (max-width: 1600px) {
  .paymentPage {
    margin-top: 0rem;
  }
}

@media (max-width: 1000px) {
  .paymentPage {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr 8rem;
  }
  .paymentContainer {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }
  .stripePayment__button_layout {
    grid-column: 1 / -1;
    grid-row: 3 / -1;
    align-items: flex-start;
  }
  .stripePayment__formgroup_iconBox {
    padding: 2rem 2rem;
  }
  .stripePayment__formgroup_icon {
    height: 2rem;
    width: 2rem;
  }
}

@media (max-width: 768px) {
  .paymentPage {
    margin: 0;
    margin-right: 0;
  }
  .paymentCardContainer {
    /* grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr); */
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  /* .stripePayment__formgroup1 {
    grid-column: 1/-1;
    grid-row: 1/2;
  }
  .stripePayment__formgroup2 {
    grid-column: 1/-1;
    grid-row: 2/3;
  }
  .stripePayment__formgroup3 {
    grid-column: 1/-1;
    grid-row: 3/4;
  }
  .stripePayment__formgroup4 {
    grid-column: 1/-1;
    grid-row: 4/5;
  }
  .stripePayment__formgroup5 {
    grid-column: 3/-1;
    grid-row: 2/-1;
  } */
}
