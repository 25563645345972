.ManageClients {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    bottom: 3rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.ManageClients__heading {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
}

.ManageClients__options--outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    margin: 1rem;
    padding: 2rem 2rem 2rem 2rem;
    border-radius: 25px;
    position: relative;
    gap: 2rem;
    /* min-height: 30rem; */
}

.ManageClients__option {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    background-color: var(--white);
    /* padding: 2rem 3rem 3rem 3rem; */
    padding: 3rem;
    /* padding-bottom: 4rem; */
    box-shadow: var(--box-shadow1);
    border-radius: 3rem;
    position: relative;
    min-width: 28rem;
    min-height: 18rem;
}

.ManageClients__option--toogle {
    /* height: 5rem;
 */
    gap: 2rem;
    display: flex;
}

.Manage__option--secondrow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    flex-direction: row;
    padding: 1rem 1rem;
    box-shadow: var(--box-shadow1);
    border-radius: 25px;
}

.ManageClients__option--secondrow--icon-1 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 0.5rem;
    background-color: none;
}

.ManageClients__option--secondrow--icon-2 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding-left: 1.2rem;
}

.ManageClients__option--logo {
    width: 15rem;
}

.ManageClients__option--heading {
    font-size: 1.5rem;
    font-weight: 600;
}

.ManageClients__option--provider {
    font-size: 1.2rem;
    font-weight: 500;
    font-style: italic;
    color: var(--primary-color);
}

.ManageClients__option--link {
    padding: 1rem;
    border-radius: 10rem;
    display: flex;
    align-items: flex-start;

    font-family: inherit;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.ManageClients__option--link-1 {
    background-color: var(--primary-color);
    padding: 1rem 2rem;
    border-radius: 10rem;
    display: flex;
    align-items: flex-start;
}

.ManageClients__option--linkicon {
    height: 2rem;
    width: 2rem;
    fill: var(--primary-color);
}

.ManageClients__option--deleteicon {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
}

.error_message__deleteClient {
    color: var(--red);
    font-weight: bold;
}

.ManageClients__option--icon {
    height: 2.5rem;
    width: 2.5rem;
    fill: var(--white);
}
.text_wrap_desc{
    white-space: break-spaces !important;
}

@media (max-width: 767px) {
    .ManageClients {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }

    .ManageClients__option {
        min-width: 24rem;
    }

    .ManageClients__options--outer {
        align-items: center;
    }

    .Manage__option--secondrow {
        gap: 3rem;
    }
}

@media (max-width: 1024px) {
    .ManageClients {
        align-items: center;
        justify-content: center;
    }
}