.videoDoc__icon {
    fill: var(--primary-color);
    height: 5rem;
    width: 5rem;
}
/* 
img {
    max-width: 40rem;
} */

.termsPdfContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90vh;
}

.PI-Container {
    width: 90%;
    height: 100%;
}

.attestContainer {
    font-size: 14px;
    margin: 1rem 2rem 0 2rem;
}

.pdfButtonContainer {
    padding: 1rem;
}
.urls-text a{
    text-decoration: none; 
}