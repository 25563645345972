.simpleButton {
	align-self: flex-end;
	border: none;
	background-color: transparent;
	font-family: inherit;
	font-weight: 600;
	color: var(--primary-color);
	cursor: pointer;
	outline: none;

	display: flex;
	gap: .5rem;
	align-items: center;
	transition: all .4s ease;
}
.simpleButton__icon {
	height: 2rem;
	width: 2rem;
	fill: var(--primary-color);
	transition: all .4s ease;
}
.simpleButton:hover {
	color: var(--dark-color);
}
.simpleButton:hover .simpleButton__icon {
	fill: var(--dark-color);
}
