.container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  height: 100%;
  overflow: auto;
  position: relative; 
  min-height: 70vh;
}
.success_heading {
  font-size: 4rem;
  font-weight: 200;
}
.success_sub_heading {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3;
}
.success_desc {
  font-size: 1.3rem;
  max-width: 50rem;
  text-align: center;
}
.success_tip_box {
  align-self: stretch;
  margin-top: 2rem;
  padding: 1.5rem; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.success_tip {
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--dark-color);
  /* width: 30rem;   */
  font-weight: 400;   
}


.success__button_layout {
  grid-column: 1 / -1;
  grid-row: 2/ -1;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.success__button {
  margin-top: 2rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
  margin-bottom: 2.5rem;
}

.success__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.success__button:hover .success__button_iconBox {
  background-color: var(--bg-primary);
}

.success__button:hover .success__button_icon {
  fill: var(--primary-color);
}

.success__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.success__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.success__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
} 

.success_image {
  height: 20rem;
  margin-top: 2rem;
}
 
@media (max-width: 767px) {
  .success_heading {
    font-size: 3rem;
  }
  .success_tip_box {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
