.content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-right: 1rem;
	height: 85vh;
	overflow: auto;
}

.error_data {
	font-size: 1.5rem;
	font-weight: 600;
}

.card {
	background-color: var(--white);
	border-radius: 2rem;
	padding: 3rem;
}

.card_heading {
	font-size: 1.4rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
}

.card_content {
	display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.card_line { 
	width: 2px;
	background-color: var(--table-heading);
	align-self: center;
}

.card_default {
	display: grid;
	gap: 2rem;
}

.card_select_default,
.card_uploaded {
	background-color: var(--bg-primary);
	padding: 2rem;
	border-radius: 2rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-start;
}

.card_uploaded,
.pdf_container {
	border-radius: 2rem;
	position: relative;
	overflow: hidden;
}

.card_pdf_default_text {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 10rem;
	width: 10rem;
	background-color: var(--primary-color);
	clip-path: polygon(100% 0, 0 100%, 100% 100%);
	display: flex;
	align-items: center;
	justify-content: center;
}

.card_pdf_default_text p {
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: var(--white);
	transform: translate(1.5rem, 1.8rem) rotate(-45deg);
	text-align: center;
}

.card_pdf_default_text2 {
	position: absolute;
	left: 0;
	top: 0;
	height: 10rem;
	width: 10rem;
	background-color: var(--primary-color);
	clip-path: polygon(0 100%, 0 0, 100% 0);
	display: flex;
	align-items: center;
	justify-content: center;
}

.card_pdf_default_text2 p {
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: var(--white);
	transform: translate(-1.5rem, -1.8rem) rotate(-45deg);
	text-align: center;
}

.card_select_default {
	gap: 1rem;
	justify-content: center;
}

.card_default_content {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.card_default_content select {
	background-color: var(--white);
	border: none;
	outline: none;
	font-family: inherit;
	padding: .5rem 2rem;
	border-radius: 1rem;
}

.card_uploaded .card_pdf .card_pdf_frame {
	height: 18rem;
	width: calc(28vw - 4rem);
}

.card_pdf {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.card_pdf_frame {
	height: 20rem;
	width: 28vw;
	border-radius: 2rem;
	overflow: hidden;
}

.card_pdf_content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card_pdf_content p {
	font-size: 1.2rem;
	font-weight: 600;
}

.card_pdf_content button {
	font-family: inherit;
	border: none;
	background-color: transparent;
	text-decoration: underline;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .4s;
}

.card_pdf_content button:hover {
	color: var(--primary-color);
}

.card_upload {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: flex-start;
}

.card_upload_title {
	font-size: 1.3rem;
	font-weight: 600;
}

.card_upload_input {
	display: flex;
	flex-direction: column;
}

.card_upload_input label {
	font-size: 1.2rem;
	font-weight: 500;
}

.card_upload_input input {
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
}

.card_upload_error {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--red);
}

.card_upload_btn {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
	padding: .5rem 2rem;
	border-radius: 1rem;
	color: var(--white);
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 500;
	cursor: pointer;
	transition: all .4s;
}

.card_upload_btn:hover {
	background-color: transparent;
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
}

.card_upload_btn:disabled {
	background-color: var(--light-dark);
	border: 1px solid var(--light-dark);
	cursor: not-allowed;
}

.card_upload_btn:disabled:hover {
	background-color: var(--light-dark);
	border: 1px solid var(--light-dark);
	color: var(--white);
}
.card_upload {
	width: 20% !important;
}
@media only screen and (max-width: 1700px) {
	.card_line {
		display: none;
	}
}

@media only screen and (max-width: 1500px) {
	.card_content {
		flex-wrap: wrap;
	}

	.card_uploaded .card_pdf .card_pdf_frame {
		width: calc(70vw - 4rem);
	}

	.card_pdf_frame {
		width: 70vw;
	} 
}

@media only screen and (max-width: 1150px) {
	.card_uploaded .card_pdf .card_pdf_frame {
		width: calc(68vw - 4rem);
	}

	.card_pdf_frame {
		width: 68vw;
	}

	.card_upload {
		width: 100% !important;
	}
}

@media only screen and (max-width: 1024px) {
	.card_uploaded .card_pdf .card_pdf_frame {
		width: calc(90vw - 4rem);
	}

	.card_pdf_frame {
		width: 90vw;
	}
	
	.card_upload {
		width: 100% !important;
	}
}

@media only screen and (max-width: 728px) {
	.card {
		padding: 2rem;
	}

	.card_uploaded .card_pdf .card_pdf_frame {
		width: calc(80vw - 4rem);
	}

	.card_pdf_frame {
		width: 75vw;
	}

	.card_pdf_content {
		flex-wrap: wrap;
	}
	
	.card_upload {
		width: 100% !important;
	}
}