.document_landingPage {
 border: solid 0.5pt;
 padding: 2rem;
}

.document_landingPage :hover {
 cursor: pointer;
}
.documents--button--Container {
 display: flex;
 gap: 2rem;
}
.docsContainer--form {
 /* width: 140vh; */
 margin: 2rem 0 2rem 0;
 height: 70vh;
 overflow: auto;
 padding-left: 1rem;
 padding-right: 1rem;
}
.docContainer--terms h2 {
 font-weight: 400;
 padding-bottom: 0.5rem;
}
.docContainer--terms p {
 font-size: 1.2rem;
 font-weight: 500;
 padding-bottom: 0.5rem;
}

@media (max-width: 767px) {
 .documents--button--Container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
 }
}
