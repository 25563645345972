.subscription_heading {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.subscriptionContainer__box_right {
  display: grid;
  gap: 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  grid-template-columns: 0vw calc(100% - 4vw - 10rem);
  margin: 3rem 0rem;
}

.subscriptionContainer__box_logo {
  height: 4rem;
}

.subscriptionContainer__heading {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.subscriptionContainer__heading span {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
}

.subscriptionContainer {
  display: grid;
  height: 100%;
  align-items: center;
}

.subscription_cards {
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 33.3% 33.3% 33.3%;
  justify-content: center;
}

.subscriptionContainer__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  background-color: var(--primary-color);
  height: 14%;
  width: 100%;
}

.subscriptionContainer__box {
  margin-top: 0;
  height: 100%;
  gap: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subscriptionContainer__form {
  margin-top: 2rem;
  margin-left: 3rem;
}

.subscriptionContainer__button {
  margin-top: 1.5rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
}

.subscriptionContainer__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.subscriptionContainer__button:hover .subscriptionContainer__button_iconBox {
  background-color: var(--bg-primary);
}

.subscriptionContainer__button:hover .subscriptionContainer__button_icon {
  fill: var(--primary-color);
}

.subscriptionContainer__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.subscriptionContainer__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.subscriptionContainer__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
}

.subscriptionContainer__button_layout {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionContainer__button:disabled {
  background-color: var(--white);
  color: #8e8b8b;
}

.subscriptionContainer__button:disabled .subscriptionContainer__button_iconBox {
  background: #8e8b8b;
}

.subscriptionContainer__button:disabled .subscriptionContainer__button_icon {
  fill: var(--white);
}

.subscriptionContainer__box_left {
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
}

.payment_card_heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #00b6ee;
  margin: 2rem 0;
}

.order_summary_container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  background: white;
  border-radius: 2rem;
  padding: 2rem 3rem;
}

.summary_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #292929;
  margin-bottom: 2rem;
}

.item_title {
  font-size: 1.5rem;
  font-weight: 600;
}

.item_description {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--light-dark);
}

.payment_card_price {
  font-size: 2.5rem;
  font-weight: 600;
}

.promo_code {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: #292929;
  cursor: pointer;
  flex-direction: column;
}

.dis_item {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.dis_item_title {
  font-size: 1.5rem;
  font-weight: 500;
}

.dis_item_dis {
  font-size: 1.1rem;
  color: var(--light-dark);
}

.dis_price {
  font-size: 1.8rem;
  font-weight: 600;
}

.order_summary_heading {
  font-weight: 600;
  font-size: 2.5rem;
}

.order_summary_heading span {
  font-size: 1.3rem;
}

.order_summary_empty_heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: rgba(154, 154, 154, 0.9);
}

.summary_content {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0rem;
}

.total_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  /* border-top: 1px solid #d1d1d1; */
}

.order_summary_total {
  font-size: 1.5rem;
  font-weight: 600;
}

.cart_price,
.cart_total {
  font-weight: 400 !important;
}

.textBox__input {
  width: 100%;
}

.PromoCodeAccordion_accordion {
  height: 2rem !important;
}

/* 
@media (max-width: 1600px) {
  .subscriptionContainer__box_logo {
    height: 8rem;
  }
  .subscriptionContainer__box {
    grid-template-columns: 20vw calc(100% - 20vw - 2rem);
  }
  .subscriptionContainer__bg {
    width: calc(20vw + 5rem);
  }
  .subscriptionContainer__box_left {
    align-items: center;
    justify-content: center;
  }
} 

@media (max-width: 1400px) {
  .subscriptionContainer__box_logo {
    height: 6rem;
  }
}

@media (max-width: 1600px) {
  .subscriptionContainer__box_left {
    align-items: stretch;
    justify-content: flex-start;
  }
}
 
@media (max-width: 1600px) {
  .subscription_heading {
    display: none;
  }
  
 .subscription_cards {
    justify-content: center;
  }.subscriptionContainer__box_left {
    margin-top: 0;
    height: 100%;
    align-items: stretch;
    justify-content: flex-start;
  } 

  .subscriptionContainer__box { 
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
  }

  .subscriptionContainer__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    background-color: var(--primary-color);
    height: 40%;
    width: 100%;
  }

  .subscriptionContainer__heading {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .subscriptionContainer__heading span {
    display: inline;
    font-size: 2rem;
    font-weight: 400;
  }
}*/

@media (max-width: 1400px) {
  .subscriptionContainer__box_logo {
    height: 2rem;
  }

  .subscriptionContainer__box_right {
    margin: 1.5rem 0rem;
  }

  .subscriptionContainer__bg {
    height: 15%;
  }
}

@media  (max-width: 1600px) {
  .subscriptionContainer__box_logo {
    height: 4rem;
  }

  .subscriptionContainer__heading {
    font-size: 1.6rem;
  }

  .subscriptionContainer__heading span {
    font-size: 1.6rem;
  }
}


@media (max-width: 1024px) {
  .subscriptionContainer {
    display: grid;
    height: 100vh;
    align-items: center;
  }

  .subscription_cards {
    grid-template-columns: 1fr 1fr;
  }
}



@media (max-width: 998px) {
  .subscription_cards {
    grid-template-columns: 1fr;
  }

  .subscriptionContainer__box {
    align-items: stretch;
  }

  .order_summary_container {
    padding: 1.5rem;
  }

  .summary_item,
  .dis_item,
  .total_price {
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .subscriptionContainer {
    display: flex;
    height: 100vh;
    align-items: stretch;
  }

  .subscriptionContainer__bg {
    height: 20%;
  }

  .subscriptionContainer__box {
    margin: 1.5rem;
    margin-top: 0rem;
    padding: 1rem;
    height: 95vh;
  }

  .subscriptionContainer__box_right {
    margin-top: 1rem;
    gap: 1.5rem;
    display: flex;
  }

  .subscriptionContainer__box_left {
    margin-top: 0;
    padding-right: 1rem;
  }

  .subscriptionContainer__box_logo {
    height: 2rem;
  }

  .subscriptionContainer__box_image {
    display: none;
  }

  .subscriptionContainer__form {
    width: 100%;
    margin: 0;
    overflow: auto;
  }

  .subscriptionContainer__button {
    margin-top: 0;
  }

  .subscriptionContainer__formgroup_inputBox {
    width: 100%;
  }

  .subscriptionContainer__formgroup_btn {
    width: 100%;
    justify-content: center;
  }

  .subscriptionContainer__formgroup_forgotPassword {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}