.registerContainer__box {
 grid-column: 2 / -2;
 height: 80vh;
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 gap: 2rem;
 box-shadow: var(--box-shadow1);
 border-radius: 1rem;
 overflow: hidden;
}

.register__admin-header {
 margin-left: 1rem;
}
/* .registerContainer__form {
 margin-top: 2rem;
 margin-left: 3rem;
} */

/* @media (max-width: 1024px) {
 .registerContainer__box {
  height: 100%;
 }
} */
@media (max-width: 1024px) {
 .registerContainer__box {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
 }
 .registerContainer__form {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
 }

 .flex-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
 }
 .registerContainer__formgroup--inputBox {
  width: 100%;
 }
 .addressContainer__formgroup--inputBox {
  width: 100%;
 }
}
