.stripePayment__form {
  margin-top: 2rem;
  margin-left: 3rem;
}

.stripePayment__button {
  margin-top: 1.5rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
}

.stripePayment__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.stripePayment__button:hover .stripePayment__button_iconBox {
  background-color: var(--bg-primary);
}

.stripePayment__button:hover .stripePayment__button_icon {
  fill: var(--primary-color);
}

.stripePayment__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.stripePayment__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.stripePayment__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
}

.stripePayment__button_layout {
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stripePayment__button:disabled {
  background-color: var(--white);
  color: #8e8b8b;
}

.stripePayment__button:disabled .stripePayment__button_iconBox {
  background: #8e8b8b;
}

.stripePayment__button:disabled .stripePayment__button_icon {
  fill: var(--white);
}

.stripePayment__form {
  margin-top: 2rem;
  margin-left: 3rem;
}

.stripePayment__form_heading {
  font-size: 2rem;
  font-weight: 100;
  color: var(--primary-color);
}

.stripePayment__formgroup {
  margin: 0;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.stripePayment__formgroup_label {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
}

.stripePayment__formgroup_inputBox {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 10rem;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  overflow: hidden;
  width: 20vw !important;
  height: 5rem;
}

.stripePayment__formgroup_iconBox {
  display: flex;
  align-items: center;
  width: 15rem !important;
}

.stripePayment__formgroup_icon {
  height: 3rem;
  width: 3rem;
  fill: var(--white);
}

.stripePayment__formgroup_input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
  padding-left: 3rem;
}

.stripePayment__formgroup--btn {
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
}

.stripePayment__button {
  margin-top: 1.5rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding-left: 2rem;
  transition: all 1s;
}

.stripePayment__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.stripePayment__button:hover .stripePayment__button_iconBox {
  background-color: var(_bg-primary);
}

.stripePayment__button:hover .stripePayment__button_icon {
  fill: var(--primary-color);
}

.stripePayment__button_iconBox {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 1s;
}

.stripePayment__button_icon {
  fill: var(--white);
  height: 3rem;
  width: 3rem;
  transition: all 1s;
}

.stripePayment__button_text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.paymentCardContainer {
  width: 100%;
  transition: all 0.4s;
  display: grid;
  gap: 4rem;
  grid-template-columns: 6fr 3fr 3fr;
}

.paymentCardNumber {
  border: none;
  padding: 0rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
}

.paymentCardLabel {
  font-size: 1.2rem;
  font-weight: 400;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s;
}

@media (max-width: 1200px) {
  .stripePayment__formgroup_inputBox {
    width: 80% !important;
  }
}
@media (max-width: 768px) {
  .stripePayment__formgroup_inputBox {
    width: 95% !important;
  }
}
