.summaryContainer {
   width: 100%;
   height: 85vh;
   display: flex;
   justify-content: center;
   align-items: center;
   /* flex-direction: column; */
}

.Summary__width__Container {
   max-width: 1170px;
   width: 100%;
   height: 100%;
   margin: 0 auto;
   display: table;
}

.printButtonContainerMain {
   display: flex;
   justify-content: flex-end;
   gap: 2rem;

   margin-bottom: 1rem;
}

.printButton {
   gap: 0.5rem;
   border: 1px solid var(--primary-color);
   background: var(--primary-color);
   color: var(--white);
   font-family: inherit;
   border-radius: 1.5rem;
   padding: 1rem 2rem;
   box-shadow: var(--box-shadow-input);
   font-size: 1.3rem;
   line-height: 1;
   outline: none;
   cursor: pointer;
   transition: all 0.4s;
   display: flex;
   align-items: center;
}

.printText {
   color: var(--white);
   font-weight: bold;
}

.print__button--icon {
   height: 1.5rem;
   width: 1.5rem;
   fill: var(--white);
}

/* pdfContainers */
.pdfMainContainer {
   border: 3px solid #000;
   max-width: 1170px;
   margin: 0 auto;
   background-color: var(--white);
   width: 100%;
   height: 150vh;
   margin-bottom: 2rem;
   position: relative;
}

.pdfHeader {
   border-bottom: 3px solid #000;
   /* background-color: yellowgreen; */
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 15rem;
   padding: 1rem;
}

.pdfHeader_heading {
   font-size: 16px;
   text-align: center;
   /* line-height: 26px; */
   font-size: 20px;
   text-transform: uppercase;
   /* padding: 8px 0; */
   font-weight: 500;
   margin: 0;
   color: #000;
}

.pdfHeader__logo {
   margin: 3rem;
}

.pdfHeader__picLogo {
   width: 100%;
   height: 5rem;
}

.pdfHeader__suremedLogo {
   height: 10rem;
}

.Page1__mainContainer {
   background-color: var(--pdf-background);
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: space-between;
}

.column1__Container {
   width: 100%;
}

.patientInfo h3 {
   font-weight: 500;
}

.patientInfo {
   border: 2px solid #bdbec1;
}

.patientInfo__main {
   background-color: var(--white);
   /* border: 2px solid #bdbec1; */
}

.riskRating h3 {
   font-weight: 500;
}

.functionLevel h3 {
   font-weight: 500;
}

.assessmentScore h3 {
   font-weight: 500;
}

.redFlag {
   display: flex;
}

.redFlag h3 {
   font-weight: 500;
}

.disclose h3 {
   font-weight: 500;
}

.column2__Container {
   width: 100%;
}

.column3__Container {
   width: 100%;
}

.pdfMain__Questions {
   height: 115vh;
}

.QuestionTitle__HeaderContainer {
   padding-top: 1rem;

   display: flex;
   justify-content: center;
}

.QuestionTitle__Header--title {
   text-decoration: underline;
   font-weight: 500;
   font-size: 32px;
}

.pdfFooterContainer {
   position: relative;
   bottom: 0;
   width: 100%;
   height: 20%;
   border-top: 3px solid var(--dark-color);
   background-color: var(--white);

   /* overflow: hidden; */
}

.footerTop {
   /* bottom: 0;
 border-top: 3px solid var(--dark-color);
 background-color: var(--white);
 width: 100%;
 height: 20%;
 padding-top: 1rem;
 display: flex;
 flex-direction: row;
 justify-content: center;
 gap: 2rem;
 */
 background-color: white;
 border: 3px solid #000;
 float: left;
 width: 100%;
 padding: 10px;
 border-top: 2px solid;
 padding-left: 4rem;
 padding-right: 4rem;
 margin-bottom: 10px;
}

.footerTop_new { 
   background-color: white;
   border: 3px solid #000;
   float: left;
   width: 100%;
   padding: 10px;
   border-top: 2px solid;
   padding-left: 2rem;
   padding-right: 4rem;
   padding-top: 0%;
}

.bannerAdd_Container_new {
   float: left;
   width: 74%;
   background-repeat: no-repeat;
   background-size: cover; 
}

.bannerAdd_Container_new img {
   width: 100% !important;
}

.footerImage1 {
   max-width: 300px;
   width: 100%;
   /* font-size: 15px;
 padding: 5px 10px;
 font-weight: 600;
 line-height: 1.4 !important; */
}

.footerImage1_abs {
   position: absolute;
   max-width: 300px;
   width: 100%;
}

.footerImage {
   min-width: 100%;
   max-width: 100%;
   /* height: 100px; */
}

.bannerAdd_Container {
   float: left;
   width: 74%;
}

.footerTop__Text {
   width: 100%;
   font-size: 14px;
   font-weight: bold;
   /* color: #525f7f; */
   color: black;
}

.hydrocodoneTableContainer {
   float: left;
   width: 45%;
   padding-right: 2rem;
}

.Tableheader {
   width: 100%;
   display: flex;
   flex-direction: row;
   background-color: var(--font-purple);
}

.tableDivision {
   flex: 1;
   border: 1px solid grey;
}

.TableContent {
   display: flex;
   flex-direction: row;
   text-align: center;
}

.summaryTabletable {
   width: 100%;
   margin-bottom: 1rem;
   background-color: initial;
}

.table-bordered {
   width: 90%;
   float: right;
   border-spacing: 0;
   margin: 0;
}

.hydrocodoneTable__Heading {
   text-align: center;
   width: 100%;
   background-color: var(--font-purple);
   font-size: 10px;
   color: var(--white);
}

.hydrocodoneTable__Heading th {
   /* width: 20rem; */
   font-size: 12px;
   text-align: center;
   font-weight: 400;
   /* padding: 1rem; */
}

.hydrocodoneTable td {
   text-align: center;
   font-size: 10px;
   font-weight: 300;
}

.summaryTable_body tr td {
   text-align: center;
   border: 1px solid grey;
   font-weight: bold;
   padding: 5px;
}

.PIContainer {
   display: flex;
   align-items: flex-end;
   justify-content: center;
   width: 25%;
   height: 100%;
   float: right;
   padding-right: 0 !important;
   padding-bottom: 0 !important;
}

.PIpdf {
   width: 90%;
   /* max-height: 100px; */
   max-height: 125px;
   height: 100%;
   padding-right: 5px;
   float: left;
}

.zoomButton {
   /* margin-right: 2rem; */
   border: 1px solid var(--primary-color);
   background: var(--primary-color);
   box-shadow: var(--box-shadow-input);
   color: var(--white);
   font-family: inherit;
   border-radius: 4px;
   padding: 0.25rem;
   outline: none;
   cursor: pointer;
   display: flex;
   align-items: center;
   /* position: absolute; */
   float: right;
   bottom: 0;
}

.footerBottom {
   background-color: var(--dark-color);
   padding-left: 0.5rem;
   padding-right: 0.5rem;
   border: 1px solid #000;
}

.footerBottom__Text {
   color: var(--white);
   font-size: 11px;
   text-align: center;
   padding: 10px 1px;
   margin: 0;
   line-height: 15px;
}

.ads-text {
   padding: 5px;
   position: relative;
   font-size: 14px;
   line-height: 1.11;
   text-align: center;
   font-weight: 600;
   letter-spacing: 0;
   background-color: #fff;
   text-transform: uppercase !important;
} 

@media (max-width: 1260px) {
   /* .footerTop {
  gap: 0.5rem;
 }
 .footerTop__Text {
  font-size: 8px;
  font-weight: bold;
 }
 .bannerAdd_Container {
  width: 60%;
 }

 .hydrocodoneTableContainer {
  width: 100%;
 }
 .Tableheader {
  width: 100%;
 }
 .hydrocodoneTable__Heading {
  font-size: 8px;
 } */
   /* .footerTop {
  height: max-content;
 }


 .hydrocodoneTable__Heading {
  font-size: 8px;
 } */

   /* .hydrocodoneTable td {
  text-align: center;
  font-size: 10px;
  font-weight: 300;
 }
 .PIpdf {
  width: 80%;
  height: 100px;
 } */
}

@media (max-width: 991px) {
   .footerTop {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;
      width: 100%;
      height: fit-content;
      padding-left: 10px;
      padding-right: 10px;
   }

   .footerTop__Text {
      font-size: 8px;
      text-align: center;
   }

   .hydrocodoneTableContainer {
      width: 100%;
      display: flex;
      justify-content: center;
   }

   /* .hydrocodoneTable td {
  text-align: center;
  font-size: 10px;
  font-weight: 300;
 }
*/
   .PIContainer,
   .bannerAdd_Container {
      /* float: none !important;
  display: table !important;
  margin: 0 auto !important; */
      width: 100%;
      padding: 10px 10px 10px 0;
   }

   .bannerAdd_Container {
      display: flex;
      justify-content: center;
   }

   .footerImage {
      min-width: 91%;
      max-width: 91%;
      /* padding-right: 5px; */
   }

   /* .PIpdf {
  width: 100%;
  height: 100px;
 } */
}