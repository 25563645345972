.subscription_card {
  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  transition: all 0.4s;
  position: relative;
}

.subscription_card_active {
  background: #00b6ee;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: var(--box-shadow1);
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  color: #ffffff;
  transition: all 0.4s;
  position: relative;
}

.subscription_title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
}

.subscription_price {
  font-size: 4rem;
  font-weight: 600;
}
.subscription_price span {
  font-size: 1.2rem;
}
.subscription_custom_heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.subscription_items {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.subscription_item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.subscription_item div {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.subscription_meta {
  font-size: 1.3rem;
  font-weight: 400;

  display: flex;
  gap: 0.2rem;
  align-items: flex-start;
  position: relative;
}
.subscription_meta_info {
  height: 1.5rem;
  width: 1.5rem;
  fill: #8e8b8b;
  transition: all 0.4s;
}
.subscription_meta_info_white {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
  transition: all 0.4s;
}
.subscription_meta_info_data {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -2.2rem;
  right: 0;
  transform: translateX(10%);
  z-index: 1000;
  white-space: nowrap;
  background-color: #8e8b8b;
  color: var(--white);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.4s;
}
.subscription_meta:hover .subscription_meta_info_data {
  opacity: 1;
  visibility: visible;
}

.subscription_total_heading {
  text-align: center;
  font-weight: 500;
  font-size: 1.3rem;
  margin-top: 3rem;
}

.subscription_total_subheading {
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
}

.outline__button {
  border: 1px solid var(--primary-color);
  background: #00b6ee00;
  color: var(--primary-color);
  font-family: inherit;
  border-radius: 15px;
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  height: 47px;
  padding: 1rem 3rem;
  /* width: 211px; */
}

.outline__button:hover {
  border: 1px solid var();
  background: var(--primary-color);
  color: var(--white);
  font-family: inherit;
  border-radius: 15px;
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  height: 47px;
  padding: 1rem 3rem;
  /* width: 211px; */
}

.loginContainer__button_layout {
  margin-top: 33px;
  /* position: absolute;
  bottom: 0;
  left: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.outline__button_active {
  border: 1px solid var(--white);
  background: #00b6ee00;
  color: var(--white);
  font-family: inherit;
  border-radius: 15px;
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  height: 47px;
  padding: 1rem 3rem;
}

.outline__button_active:hover {
  border: 1px solid var();
  background: var(--primary-color);
  color: var(--white);
  font-family: inherit;
  border-radius: 15px;
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  height: 47px;
  padding: 1rem 3rem;
}

.yesmark_icon,
.subscription_icon {
  height: 1.3rem;
  width: 1.3rem;
  fill: #292929;
}
.subscription_icon_white {
  height: 1.3rem;
  width: 1.3rem;
  fill: var(--white);
}


.card_pdf_default_text2 {
  right: 0;
  position: absolute; 
  top: 0;
  height: 10rem;
  width: 10rem;
  background-color: var(--primary-color);
  clip-path: polygon(100% 100%, 0 0, 100% 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0rem 2rem 0rem 0rem;
}

.card_pdf_default_text2 p {
  color: var(--white);
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  transform: translate(1.8rem,-1.6rem) rotate(45deg);
  text-transform: uppercase;
}


.subscription_card_active .card_pdf_default_text2{
  background-color: var(--white);
}

.subscription_card_active .card_pdf_default_text2 p{ 
  color: var(--primary-color);
}

@media (max-width: 1000px) {
  .subscription_custom_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .subscription_custom_heading::after {
    content: '-';
    font-size: 3rem;
    font-weight: 400;
    color: #00b6ee;
  }
  .subscription_custom_heading.active::after {
    content: '+';
    font-size: 3rem;
    font-weight: 400;
    color: #00b6ee;
  }

  .subscription_card {
    height: 60rem;
  }
  .subscription_card.show {
    transition: all 0.4s;
    /* min-height: 10rem; */
    height: 10rem;
    overflow: hidden;
  }
  .subscription_card.show .subscription_items,
  .subscription_card.show .subscription_total_heading,
  .subscription_card.show .subscription_total_subheading,
  .subscription_card.show .loginContainer__button_layout {
    transition: all 0.4s;
  }
  .subscription_card.show .subscription_items,
  .subscription_card.show .subscription_total_heading,
  .subscription_card.show .subscription_total_subheading,
  .subscription_card.show .loginContainer__button_layout {
    /* display: none; */
    opacity: 0;
    visibility: hidden;
  }
}
