.selfContainer {
  display: flex;
  display: flex;
  flex-direction: column;
}

.selfContainer__box {
  width: 100%;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 8rem 2rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: small;
  font-weight: 500;
}

.questionAnswer--main {
  gap: 2rem;
}

.finalMainContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.finalContainer {
  gap: 2rem;
  width: 100%;
  /* height: 30vh; */
  padding-top: 1rem;
  box-shadow: var(--box-shadow-input);
  display: flex;
  flex-direction: column;
  /* align-items: center;
 justify-content: center; */

  border-radius: 10px;
}

.staticTextContainer {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.phoneTextBox input {
  width: 30%;
  padding: 12px;

}

.phoneTextBox {
  margin-bottom: 20px;
}

.phonelabel label {
  margin-left: 10px;
}

.finalContainer__button {
  margin-top: 2rem;
  font-family: inherit;
  border: none;
  cursor: pointer;
  background-color: var(--primary-color);
  box-shadow: var(--box-shadow-input);
  border-radius: 10rem;

  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  /* padding-left: .5rem; */
  transition: all 1s;
}

.finalContainer__button--icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--white);
}

.finalContainer__button--text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 8rem;
  text-align: start;
  color: var(--white);
}

.audioContainer__formgroup--btn {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.audioContainer__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background-color: transparent;
  border: none;
}

.audioContainer__button :hover .audioContainer__button--icon {
  fill: black;
  cursor: pointer;
}

.audioContainer__button--text {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 600;
}

.audioContainer__button--text1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.audioContainer__button--BMItext {
  font-size: 1.5rem;
  font-weight: 500;
}

.audioContainer__button--iconBox {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 1s;
}

.audioContainer__button--icon {
  height: 3rem;
  width: 3rem;
  transition: all 1s;
  fill: var(--primary-color);
}

.self__switchContainer {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.switchbox-text {
  margin-left: 1.5rem;
  font-size: small;
  font-weight: 500;
}

.assessmnet__buttons {
  margin-top: 2rem;
  display: flex;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
}

.assessmnet__button {
  font-family: inherit;
  background-color: var(--white);
  border: none;
  box-shadow: var(--box-shadow1);
  border-radius: 10rem;
  cursor: pointer;

  display: flex;
  gap: 1.5rem;
  align-items: center;
  transition: all 0.4s;
}

.assessment_buttonContainer {
  display: flex;
  gap: 5rem;
}

.assessmnet__button--right {
  padding-left: 2rem;
}

.assessmnet__button--left {
  padding-right: 2rem;
}

.assessmnet__button--text {
  font-size: 1.5rem;
  font-weight: 600;
  transition: all 0.4s;
}

.assessmnet__button--iconBox {
  padding: 1rem;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}

.assessmnet__button--icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--white);
  transition: all 0.4s;
}

.assessmnet__button:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.assessmnet__button:hover .assessmnet__button--iconBox {
  background-color: var(--white);
}

.assessmnet__button:hover .assessmnet__button--icon {
  fill: var(--primary-color);
}

.progressbarContainer {
  overflow: hidden;
  display: flex;
  margin-top: 2rem;

  margin-right: 2.5rem;
  border-radius: 20px;
  clear: both;
  height: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.16);
}

.progressbar--completed {
  border-radius: 20px;
  background-color: var(--primary-color);
}

.progressbar--notcompleted {
  color: transparent;
}

.progressbar-bar-inner-completed {
  padding-right: 20px;
  width: 100%;
  text-align: end;
  color: white;
  font-weight: 500;
}

.seflAssessment1__button--text {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  min-width: 22rem;
  max-width: 22rem;
  text-align: start;
}

.assessmentCheckbox {
  min-height: 2rem;
  min-width: 2rem;
}

.bmiCalculate {
  width: 100%;
}

.bmiSection1 {
  width: 100%;
  padding: 1rem;
}

.assessmnet__buttons--finalQuestion {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.patient__flex-update-form {
  width: 50%;
  display: flex;
  gap: 1rem;
  padding-bottom: 2rem;
  margin-right: auto;
}

.patient_form_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.patient_form_input label {
  font-size: 12px;
  transition: all .4s;
}

.patient_form_input input {
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 1rem;
  border-radius: 5px;
  transition: all .4s;
  font-family: inherit;
  outline: none;
  border: 1px solid #000;
}


.patient_form_input select {
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 1rem;
  border-radius: 5px;
  transition: all .4s;
  font-family: inherit;
  outline: none;
  border: 1px solid #000;
}

.patient_form_dob {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  transition: all .4s;
  font-family: inherit;
  outline: none;
  border: 1px solid #000;
  height: 40px;
  padding: 1rem;
}

.patient_form_dob_icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 10px;
}

.fa {
  margin: 2px;
}

.tooltip {
  font-size: 1.4rem;
  position: absolute;
  color: #fff;
  font-weight: 500;
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  cursor: pointer;
  /* pointer-events: none; */
  z-index: 1000;
}

.low {
  color: red;
}

.medium {
  color: orange;
}

.high {
  color: green;
}

.icon {
  width: 26px;
  height: 26px;
}

.icon-text {
  display: inline-block;
  width: 26px;
  font-size: 2em;
  background-color: white;
}

.painlocationBox {
  display: inline-block;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 0rem;
  width: 50%;
  position: relative;
}

.painlocationNumberBox {
  display: inline-block;
  position: relative;
  margin: 0.2rem;
  width: 30px;
}

.painlocationContianer {
  display: block;
  direction: ltr;
  margin-left: 1.5rem;
}

@media (max-width: 768px) {
  .ImageMapperBody .modal__text {
    margin: 0px 10px;
  }

  .fa-3x {
    font-size: 3rem;
  }

  .painlocationNumberBox {
    display: inline-block;
    position: relative;
    margin: 0.2rem;
    width:  2.4rem;
  }

  .painlocationContianer {
    display: block;
    direction: ltr;
    margin-left: 0.5rem;
  }

  .ImageMapperBody .modal {
    height: 40vh;
  }

  .patient-update-form .selfContainer__box {
    min-height: 50vh;
  }

  .patient__flex-update-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* .audioContainer__button--iconBox {
		position: relative;
		width: 100%;
		float: left;
	}
	.audioContainer__button--icon {
		position: relative;
		width: 25%;
		float: left;
	}
	.audioContainer__button {
		width: 100%;
		position: relative;
		float: left;
	} */

  .selfContainer__box {
    width: 100%;
    min-height: 60vh;
    padding: 2rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }

  .questionAnswer--main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .audioContainer__button--text1 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .bmi--weight__container {
    align-items: center;
    justify-content: center;
  }

  .audioContainer__button--BMItext {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }

  .Container__formgroup_Previous--btn {
    width: 100%;
    position: relative;
    float: left;
  }

  .seflAssessment1__button--text {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    min-width: 15rem;
    max-width: 15rem;
    text-align: start;
  }

  .assessmnet__buttons {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 2rem;
  }

  .assessment_buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .assessmnet__button--text {
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.4s;
  }
}

/* @media (min-width: 992px) { */
/* .audioContainer__button--iconBox {
		position: relative;
		width: 100%;
		float: left;
	}
	.audioContainer__button--icon {
		position: relative;
		width: 25%;
		float: left;
	}
	.audioContainer__button {
		width: 100%;
		position: relative;
		float: left;
	} */
/* .selfContainer {
  width: 100%;
 }
 .selfContainer__box {
  width: 100%;
  padding: 2rem 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: small;
  font-weight: 500;
 }
 .Container__formgroup_Previous--btn {
  width: 28%;
  position: relative;
  float: left;
 } */
/* } */
/* @media (min-width: 1200px) { */
/* .audioContainer__button--iconBox {
		position: relative;
		width: 100%;
		float: left;
	}
	.audioContainer__button--icon {
		position: relative;
		width: 25%;
		float: left;
	}
	.audioContainer__button {
		width: 100%;
		position: relative;
		float: left;
	} */
/* .selfContainer {
  width: 100%;
 }
 .selfContainer__box {
  width: 100%;
  padding: 2rem 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: small;
  font-weight: 500;
 }
 .Container__formgroup_Previous--btn {
  width: 18%;
  position: relative;
  float: left;
 }
} */