:root {
 --bg-primary: #f5f5f5;
 --primary-color: #00b6ee;
 --dark-color: #000;
 --light-dark: #8e8b8b;
 --table-heading: #e5e5e5;
 --placeholder: rgba(0 0 0 / 15%);
 --red: #ee1d00;
 --white: #fff;
 --skeleton: #0000001c;
 --skeleton-red: #ee1c0044;
 --skeleton-blue: #00b6ee42;
 --font-purple: #243f8e;
 --pdf-background: #e7e7e8;
 --box-shadow1: 0.5rem 0.5rem 2rem 2px rgb(0 0 0 / 14%);
 --box-shadow-input: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
 --bp-largest: 1200px;
 --bp-medium: 900px;
 --bp-small: 600px;
 --bp-smallest: 400px;
}

* {
 margin: 0px;
 padding: 0px;
 box-sizing: inherit;
}

html {
 font-size: 62.5%;
}
@media only screen and (max-width: var(--bp-large)) {
 html {
  font-size: 50%;
 }
}

body {
 box-sizing: border-box;
 font-family: "Poppins", sans-serif;
 font-weight: 400;
 line-height: 1.7;
 background-color: var(--bg-primary);
}

.primary-icon {
 fill: var(--primary-color);
}
.red-icon {
 fill: var(--red);
}
.text-red {
 color: var(--red);
}
.text-primary {
 color: var(--primary-color);
}
.flex {
 display: flex;
 align-items: center;
 gap: 1rem;
}

::-webkit-scrollbar {
 width: 1rem;
 height: 1rem;
}
::-webkit-scrollbar-trackk {
 -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
 box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-track-piece {
 background-color: var(--white);
}

::-webkit-scrollbar-thumb {
 background-color: var(--primary-color);
 border-radius: 10rem;
}
