.PromoCodeAccordions {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.PromoCodeAccordion {
  overflow: hidden;
  transition: all 0.4s;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.PromoCodeAccordion_inner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.PromoCodeAccordion_accordion {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 400;
  transition: all 0.4s;
  height: 2vw;
}

.PromoCodeAccordion div .PromoCodeAccordion_accordion_title {
  color: #00b6ee;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PromoCodeAccordion_accordion::after {
  content: '+';
  font-size: 3rem;
  font-weight: 400;
  color: #00b6ee;
}

.PromoCodeAccordion_accordion.active::after {
  content: '-';
  font-size: 4.1rem;
  height: 2.6rem;
  overflow: hidden;
  margin-bottom: 1rem;
  color: #00b6ee;
  font-weight: 400;
}

.PromoCodeAccordion_list,
.PromoCodeAccordion_list ul {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.3rem;
}

.PromoCodeAccordion_accordion_icon {
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: -2rem;
  right: 0.5rem;
}

.PromoCodeAccordion_accordion_heading {
  font-size: 1.7rem;
  font-weight: 600;
  transition: all 0.4s;
}

.phase2_subheading {
  padding: 1rem 0;
  font-weight: 300;
  font-size: 1.8rem;
}

.PromoCodeAccordion_side {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.FAQ_button {
  padding: 1rem 2rem;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

/* Style the element that is used for the panel class */

.panel {
  font-size: 1.3rem;
  font-weight: 500;
  max-height: 0;
  transition: 0.4s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.PromoCodeAccordions .panel.show {
  opacity: 1;
  max-height: 12rem;
  padding-top: 1.5rem;
}

.link-to-claims {
  font-size: 1.6rem;
  text-decoration: none;
}

.panel form {
  width: 100%;
}

.PromoCodeAccordion div {
  width: 100%;
}

.panel button {
  height: 52.3px;
  border-left: 0px !important;
  font-size: 2rem;
  font-weight: 500;
  justify-content: center;
  background: #00b6ee;
  border: none;
  border-radius: 0px 3px 3px 0px;
  color: rgb(255, 255, 255);
  width: 100%;
}

.panel button:disabled {
  background: #00b6ee !important;
  cursor: default;
  background-color: #00b8f050 !important;
}

.PromoCodeAccordions .active {
  border: none !important;
}

.PromoCodeAccordion button:disabled,
.PromoCodeAccordion button[disabled] {
  background-color: #00b8f099 !important;
}

.promo_content .PromoCodeAccordion_accordion.active::after {
  content: '-';
  font-size: 4.1rem;
  height: 2.6rem;
  overflow: hidden;
  margin-bottom: 1rem;
  color: #00b6ee;
  font-weight: 400;
  font-family: 'Futura PT', sans-serif;
}

@media only screen and (max-width: 1400px) {
  .PromoCodeAccordions {
    padding: 0rem;
    margin: 0rem;
  }
}

@media only screen and (max-width: 720px) {
  .promo_content .PromoCodeAccordion {
    padding-bottom: 50px !important;
  }

  .PromoCodeAccordions {
    padding: 0rem;
    margin: 0rem;
  }

  .PromoCodeAccordion {
    width: 100%;
    padding: 1.9rem 0;
  }

  .PromoCodeAccordion__number {
    padding: 7rem 2rem;
  }
}

.stripePayment__form {
  margin-top: 2rem;
  margin-left: 3rem;
}

.stripePayment__form_heading {
  font-size: 2rem;
  font-weight: 100;
  color: var(--primary-color);
}

.stripePayment__formgroup {
  margin: 2rem 0;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
}

.stripePayment__formgroup_label {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
}

.stripePayment__formgroup_inputBox {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 10rem;
  background-color: var(--bg-primary);
  box-shadow: var(--box-shadow-input);
  overflow: hidden;
  width: 20vw;
  height: 5rem;
}

.stripePayment__formgroup_iconBox {
  padding: 2.5rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
}

.stripePayment__formgroup_icon {
  height: 3rem;
  width: 3rem;
  fill: var(--white);
}

.stripePayment__formgroup_input {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  width: 100%;
}

.stripePayment__formgroup--btn {
  /* width: 22vw; */
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
}
