.registerContainer__box--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.clinicContainer__bg {
    background-color: var(--primary-color);
    height: 100vh;
    width: 50vw;
}

.clinic--signupContainer__form {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 2rem;
}

.registerContainer__box--image {
    margin: 5rem 3rem 4rem 0;
    align-self: flex-end;
}

.registerClinic--noInvite__box--right {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.registerClinic--noInvite__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
    /* margin-left: 3rem; */
    width: 80%;
    padding: 0 3rem 0 2rem;
    gap: 1rem;
    /* height: 100%; */
}

.clinic-warning__message {
    width: 80%;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--red);
    padding: 0 2rem 0 3rem;
    /* margin-bottom: 1rem; */
}

.registerContainer__form {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: 3rem;
    width: 100%;
    /* overflow-y: auto; */
    gap: 1rem;
    /* height: 100%; */
}

.registerContainer__formgroup--inputBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 10rem;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    overflow: hidden;
    width: 100%;
    /* height: 4rem; */
    /* margin: 1rem; */
}

.addressContainer__formgroup--inputBox {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 10rem;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    overflow: hidden;
    width: 100%;
    /* height: 4rem; */
    /* margin: 1rem; */
    margin-right: 1rem;
}

.flex-form {
    display: flex;
    gap: 1rem;
}

.formgroup--validation {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
}

.registerContainer__formgroup--btn {
    display: flex;
    justify-content: flex-start;
}

.registerContainer__button--previous {
    margin: 1rem;
    font-family: inherit;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding-right: 2rem;
    transition: all 1s;
}

.registerContainer__button {
    margin: 1rem;
    font-family: inherit;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding-left: 2rem;
    transition: all 1s;
}

.invite-form-container {
    overflow: auto;
}

.smallInputContainer {
    width: 48%;
}

.invite-user-add-button {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.terms__switch {
    display: flex;
    align-items: center;
}

.terms__switchContainer {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* justify-content: flex-start; */
}

.termsContainer-clinic--signup {
    max-height: 40vh;
    overflow-y: scroll;
    text-align: left;
    font-size: small;
    width: 100%;
}

.switch-text {
    margin-left: 1.5rem;
    width: 100%;
}

.clinicSignUp__box--right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
}

.login__pagination {
    margin-top: 2rem;
    font-size: medium;
    display: flex;
    justify-content: center;
    width: 100%;
}

.login__pagination-bar {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    counter-reset: step;
    /*border-bottom: 5px solid var(--light-dark); */
}

.login__pagination-bar li {
    list-style-type: none;
    width: 33.33%;
    position: relative;
    text-align: center;
    font-weight: 600;
}

.login__pagination-bar li:before {
    content: counter(step);
    counter-increment: step;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 2px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    box-shadow: var(--box-shadow-input);
    background-color: white;
}

.login__pagination-bar li:after {
    /* CSS for creating horizontal line*/
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.login__pagination-bar li:first-child:after {
    content: none;
}

.login__pagination-bar li.active {
    color: #fff;
}

.login__pagination-bar li.active:before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.login__pagination-bar li.active+li:after {
    background-color: var(--primary-color);
}

.stepper-text {
    font-size: small;
    color: rgb(0, 0, 0);
}

.active .stepper-text {
    color: var(--primary-color);
}

.invite--delete--button {
    background-color: red;
    float: right;
}

.termsContainer__form {
    margin-top: 2rem;
    margin-left: 3rem;
}

@media (max-width: 998px) {
    .clinicContainer__bg {
        height: 25%;
    }
}

@media (max-width: 1024px) {
    .registerContainer__box--image {
        display: none;
    }

    .clinicContainer__bg {
        position: absolute;
        z-index: -1;
        top: 0;
        background-color: var(--primary-color);
        height: 30%;
        width: 100%;
    }

    .formgroup--validation {
        width: 100%;
    }

    .smallInputContainer {
        width: 100%;
    }

    .termsContainer__form {
        margin-left: 0;
        text-align: center;
    }

    .termsContainer {
        font-size: small;
        width: 100%;
    }

    .terms__switchContainer {
        align-items: flex-start;
        flex-direction: column;
        padding: 1rem 1rem;
    }

    .registerContainer__formgroup--btn {
        justify-content: center;
    }

    .clinic-warning__message {
        width: 100%;
        text-align: center;
        font-size: 1rem;
    }

    .registerClinic--noInvite__form {
        width: 100%;
    }

    .invite-form-container {
        overflow: auto;
        padding-left: 2rem;
    }

    .termsContainer-clinic--signup {
        padding: 1rem;
    }
}