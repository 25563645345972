.invite-class {
 display: flex;
 justify-content: flex-start;
 align-items: flex-start;
 gap: 2rem;
}

.pending-header {
 font-size: 1.7rem;
 font-weight: 500;
 color: var(--primary-color);
}

.filter__content1 {
 background-color: var(--white);
 border-radius: 1rem;
 padding: 2rem 3rem;
 padding-bottom: 3rem;
 display: flex;
 /* height: 16.5rem; */
 gap: 2rem;
}

.filter__content2 {
 width: 100%;
 background-color: var(--white);
 border-radius: 1rem;
 padding: 2rem 3rem;
 margin-bottom: 2rem;
 display: flex;
 flex-direction: column;
 grid-gap: 2rem;
 gap: 2rem;
}

.filter__content--top1 {
 display: flex;
 flex-direction: column;
 gap: 2rem;
 align-items: flex-start;
 flex-wrap: wrap;

 width: 35rem;
}

.filter__input--labels {
 align-self: stretch;
 display: flex;
 flex-direction: column;
 gap: 0.5rem;
}

.filter__input--label {
 font-size: 1.2rem;
 color: var(--light-dark);
}

.filter__input {
 background-color: var(--bg-primary);
 border: 1px solid var(--primary-color);
 padding: 0.5rem 1rem;
 box-shadow: var(--box-shadow-input);
 border-radius: 1rem;
 display: flex;
 align-items: center;
 gap: 1rem;
}

.filter__input--text {
 border: none;
 font-family: inherit;
 font-size: 1.2rem;
 outline: none;
 background-color: transparent;
 width: 100%;
}

.filter__input--text::-webkit-input-placeholder {
 color: var(--placeholder);
}

.pending-container {
 font-size: 1.3rem;
 display: flex;
 flex-direction: column;
}

.pending-email {
 padding-bottom: 1rem;
 padding-top: 1rem;
 width: 100%;
 display: flex;
 justify-content: space-between;
 border-bottom: 1px solid var(--placeholder);
 transition: all 0.4s;
}
.pending-email:hover {
 background-color: var(--bg-primary);
}

@media (max-width: 768px) {
 .invite-class {
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
 }
 .filter__content1 {
  position: relative;
  float: left;
 }
 .filter__input {
  width: 100%;
 }
}

@media (min-width: 992px) {
 .filter__content1 {
  position: relative;
  float: left;
 }
 .filter__input {
  width: 100%;
 }
}

@media (min-width: 1200px) {
 .filter__content1 {
  position: relative;
  float: left;
 }
 .filter__input {
  width: 35rem;
 }
}
