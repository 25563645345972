.mainContent {
    height: 70vh;
    margin-bottom: 2rem;
    overflow-y: auto; 
    background: white;
    padding: 1rem 2rem 1rem;
    border-radius: 1.6rem;
}

.dropdownLabel {
    color: #8E8B8B;
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.dropdownLabel1 {
    color: #000000;
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.cancel__button {
    padding-right: 75.75rem;
    flex-grow: 1; 
}

.mainDropdownSection {
    display: flex;
    gap: 4rem;
    padding-bottom: 4rem;
}

.mainDropdownSection1 {
    display: flex;
    background-color: #E5E5E5;
    margin-top: 1.4rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 2rem;
    border-radius: 20px;
}

.addCriteriaBtn {
    display: flex;
    justify-content: end;
}

.filter__input__select {
    border: none;
    font-family: inherit;
    outline: none;
    background-color: white;
    width: 100%;
}

.filter__input__select option {
    border-radius: 12px;
}


.header__container_btn {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    outline: none;
    font-family: inherit;
    cursor: pointer;
    padding: .5rem;
    border-radius: 2rem;
    width: 13.5rem;
    position: relative;
    transition: all 1s ease;
}

.header__container_btn p {
    display: flex;
    align-items: center;
}

.header__container--icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--white);
}

.header__container_btn span {
    font-weight: 600;
    color: var(--white);
    position: absolute;
    top: 0.2rem;
    left: 2.5rem;
    white-space: nowrap;
    margin: 0;
}

.filter__input_top3 {
    width: 35rem;
    background: white;
    border: 1px solid var(--primary-color);
    padding: 0.8rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
    margin-top: 0.4rem;
    min-height: 40px;
}

.err_msg{
    color: red;
    font-size: 1rem;
    margin-top: 0.2rem;
    display: block;
}

.filter__input__select1::placeholder{
    color: red;
}

.filter__input_top1 {
    width: 36rem;
    background: white;
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
}

.criteriaDropdownSection {
    display: flex;
    gap: 4rem;
}

.loginContainer__button {
    margin-top: 1.5rem;
    font-family: inherit;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-left: 2rem;
    transition: all 1s;
}


.loginContainer__button__iconBox {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 1s;
}

.loginContainer__button__icon {
    fill: var(--white);
    height: 2.4rem;
    width: 2.4rem;
    transition: all 1s;
}

.loginContainer__button_text {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}




.loginContainer__button1 {
    margin-top: 1.5rem;
    font-family: inherit;
    flex-direction: row-reverse;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-right: 2rem;
    transition: all 1s;
}



.loginContainer__button__iconBox {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 1s;
}

.loginContainer__button__icon1 {
    fill: var(--white);
    height: 2.4rem;
    width: 2.4rem;
    transform: rotate(180deg);
    transition: all 1s;
}

.loginContainer__button_text {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}

.bottomButtonsSection{
    display: flex;
    justify-content: space-between;
}


.mainContent {
    height: 70vh;
    margin-bottom: 2rem;
    overflow-y: auto; 
    background: white;
    padding: 1rem 2rem 1rem;
    border-radius: 1.6rem;
    
}

.bottomButtonsSection{
    display: flex;
    justify-content: space-between;
}

.dropdownLabel {
    color: #8E8B8B;
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.dropdownLabel1 {
    color: #000000;
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    padding-bottom: 0.5rem;
}

.mainDropdownSection {
    display: flex;
    gap: 4rem;
    padding-bottom: 4rem;
}

.question{
    color: #00B6EE;
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 500;
}

.answer{
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    overflow-wrap: break-word; 
    flex: 1;   
}

.question1{
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    display: block;
    margin-top: 1rem;
}

.mainDropdownSection1 {
    display: flex;
    background-color: #E5E5E5;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem 1.6rem;
    border-radius: 20px;
}

.addCriteriaBtn {
    display: flex;
    justify-content: end;
}

.filter__input__select {
    border: none;
    font-family: inherit;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.filter__input__select1 {
    border: none;
    font-family: inherit;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.filter__input__select option {
    border-radius: 12px;
}


.header__container_btn {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    outline: none;
    font-family: inherit;
    cursor: pointer;
    padding: .5rem;
    border-radius: 2rem;
    width: 13.5rem;
    position: relative;
    transition: all 1s ease;
}

.header__container_btn p {
    display: flex;
    align-items: center;
}

.header__container--icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--white);
}

.header__container_btn span {
    font-weight: 600;
    color: var(--white);
    position: absolute;
    top: 0.2rem;
    left: 2.5rem;
    white-space: nowrap;
    margin: 0;
}

.filter__input_top {
    width: 27rem;
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
}

.filter__input_top1 {
    width: 36rem;
    background: white;
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
}

.criteriaDropdownSection {
    display: flex;
    gap: 4rem;
}

.tabTitle{
    color: #00B6EE;
    font-size: 1.6rem;
    font-weight: 500;
}

.questionSection{
    display: flex;
    gap: 1rem;
}

.criteriaDropdownSection1{
    flex-direction: column;
}

.loginContainer__button {
    margin-top: 1.5rem;
    font-family: inherit;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-left: 2rem;
    transition: all 1s;
}


.loginContainer__button__iconBox {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 1s;
}

.loginContainer__button__icon {
    fill: var(--white);
    height: 2.4rem;
    width: 2.4rem;
    transition: all 1s;
}

.loginContainer__button_text {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}




.loginContainer__button1 {
    margin-top: 1.5rem;
    font-family: inherit;
    flex-direction: row-reverse;
    border: none;
    cursor: pointer;
    background-color: var(--bg-primary);
    box-shadow: var(--box-shadow-input);
    border-radius: 10rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-right: 2rem;
    transition: all 1s;
}



.loginContainer__button__iconBox {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 1s;
}

.loginContainer__button__icon1 {
    fill: var(--white);
    height: 2.4rem;
    width: 2.4rem;
    transform: rotate(180deg);
    transition: all 1s;
}

.loginContainer__button_text {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
}

.bottomButtonsSection{
    display: flex;
    justify-content: space-between;
}