.textBox {
  position: relative;
}

.form_input__error {
  font-size: 1.2rem;
  color: var(--red);
}

.textBox__label {
  position: absolute;
  top: -0.5rem;
  left: 0;
  z-index: 10;
  display: flex;
  padding: 1.3rem;
}

.textBox__label_icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: #ffffff;
}

.textBox__input {
  /* background-color: #333; */
  /* border-radius: 0.5rem; */
  overflow: hidden;
  min-width: 30rem;
  display: flex;
  /* padding: 1rem 1rem 1rem 0rem; */
  box-shadow: var(--box-shadow-input);
  border: 1px solid var(--primary-color);
  background-color: var(--white);
  border-radius: 1rem;
}

.textBox__input_box div {
  border: none;
  border-color: transparent;
}

.textBox__input_box option {
  background-color: #333;
  border: none;
  border-color: var(--dark-grey);
}

.textBox__input--show {
  margin-right: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.textBox__input--show:hover .textBox__input--showIcon {
  fill: #ff3366;
}

.textBox__input--showIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-grey);
  transition: all 0.4s;
}

.form_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form_input__label {
  color: var(--light-grey);
  font-size: 1.2rem;
}

.css-1pndypt-Input input {
  opacity: 1 !important;
}

.css-vwja0k input {
  opacity: 1 !important;
}
