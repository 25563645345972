.registerPatient__box--right {
    overflow-y: auto;
    /* display: flex;
 flex-direction: column;
 justify-content: center; */
    height: 100%;
}

.registerPatient__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 5rem 3rem 0 2rem;
    gap: 2rem;
}

.patient__flex-form {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;
}

.date-flex {
    justify-content: space-between;
}

.cellphoneContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 1rem;
}

.cellphoneContainer--yesno {
    display: flex;
    gap: 2rem;
}

.cellphone-yesno {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cellphone-yesno span {
    font-size: 15px;
}

.terms__pagination {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.terms__pagination-bar {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    counter-reset: step;
    /*border-bottom: 5px solid var(--light-dark); */
}

.terms__pagination-bar li {
    list-style-type: none;
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: 600;
}

.terms__pagination-bar li:before {
    content: counter(step);
    counter-increment: step;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 2px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    box-shadow: var(--box-shadow-input);
    background-color: white;
}

.terms__pagination-bar li:after {
    /* CSS for creating horizontal line*/
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.terms__pagination-bar li:first-child:after {
    content: none;
}

.terms__pagination-bar li.active {
    color: #fff;
}

.terms__pagination-bar li.active:before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.terms__pagination-bar li.active+li:after {
    background-color: var(--primary-color);
}

.stepper-text {
    font-size: small;
    color: rgb(0, 0, 0);
}

.active .stepper-text {
    color: var(--primary-color);
}

.termsContainer {
    width: 100%;
    padding: 2rem 2rem 0 2rem;
    max-height: 50vh;
    overflow-y: scroll;
}

.terms__Heading {
    font-weight: 600;
}

.terms__SubHeading {
    margin-top: 1rem;
    font-weight: bold;
    font-style: italic;
}

.terms__SubHeading2 {
    margin-top: 1rem;
    font-weight: bold;
}

.terms__paragraphs {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.terms__paragraphs p {
    line-height: 2.5rem;
}

.patient-warning__message {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--red);
    margin-left: 1.2rem;
    padding-bottom: 1rem;
}

.Patient--warning--link {
    color: var(--primary-color);
    text-decoration: none;
}

.PatientDescriptionMsg {
    width: 100%;
    font-weight: 500;
    font-size: 1.3rem;
    color: black; 
    margin-left: 1rem;
}

@media (max-width: 1024px) {
    .registerContainer__box {
        overflow: scroll;
    }

    .Patient--warning--link {
        color: var(--red);
        font-weight: bold;
    }
}

@media (max-width: 767px) {
    .clinicContainer__bg {
        height: 25%;
    }

    .registerContainer__box {
        margin: 2rem;
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        /* overflow: auto; */
    }

    .patient__flex-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .patient-warning__message {
        text-align: center;
        font-size: 1rem;
    }

    /* .loginContainer__box--right {
  display: none;
 } */
    .registerPatient__box--right {
        justify-content: center;

        /* overflow-y: scroll; */
        /* padding-bottom: 5rem; */
    }

    .registerPatient__form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0;
        margin-left: 0;
        height: 100%;
        padding: 5rem 1rem 0 1rem;
        /* overflow: auto; */
    }

    .cellphoneContainer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        margin: 1rem;
        text-align: center;
    }

    .cellphoneContainer--yesno {
        display: flex;
        gap: 4rem;
    }
}