.filter__content3 {
	background-color: var(--white);
	border-radius: 1rem;
	padding: 2rem 3rem;
	padding-bottom: 3rem;
	display: flex;
	/*justify-content: center;
    align-items: center; */
	flex-direction: column;
	height: 25rem;
	gap: 2rem;
}

.website--text {
	font-style: italic;
	color: var(--light-dark);
}
