/* Modal */

.modalContainer {
  display: block;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  -moz-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 99999;
}

.modal {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-primary);
  border-radius: 2rem;
  overflow: hidden;
  transition: 1.1s ease-out;
  box-shadow: var(--box-shadow1);
  /* filter: blur(0); */
  opacity: 1;
  visibility: visible;
  justify-content: center;
  min-width: 25%;
}

.modal__heading {
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.modal__heading--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.modal__heading--videoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal__heading2 {
  font-weight: 600;
}

.modal__heading--btn {
  font-family: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}

.modal__heading--icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--red);
}

.modal__heading--btn:hover {
  transform: scale(1.1);
}

.modal__data {
  padding-bottom: 2rem;
  margin: 1rem 2rem;
  border-bottom: 1px solid var(--table-heading);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal__data--completed {
  background-color: var(--primary-color);
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  color: var(--white);
  font-size: 1.2rem;
}

.modal__data--completedIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}

.modal__data--timedate {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  color: var(--light-dark);
  font-size: 1.2rem;
}

.modal__data--timedateIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--light-dark);
}

.modal__data--content {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  color: var(--primary-color);
  font-size: 1.1rem;
}

.modal__data--contentIcon {
  height: 2rem;
  width: 2rem;
  fill: var(--primary-color);
}

.modal__data--user {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 2rem;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--light-dark);
}

.modalContainer__form {
  margin: 1rem 2rem;
}

.modal__actions {
  padding: 1rem;
  padding-left: 2rem;
  display: flex;
  gap: 0.5rem;
}

.modal__button {
  font-family: inherit;
  border-radius: 1.5rem;
  padding: 1rem 3rem;
  box-shadow: var(--box-shadow-input);
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button--delete {
  border: 1px solid var(--red);
  background: var(--red);
  color: var(--white);
}

.modal__button--delete:hover {
  background: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.modal__button--update {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--white);
}

.modal__button--update:hover {
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.modal__button--update:disabled {
  background: var(--light-dark);
  border: 1px solid var(--light-dark);
  color: var(--white);
  box-shadow: var(--box-shadow1);
  cursor: not-allowed;
}

.modal__button--cancel {
  font-family: inherit;
  border-radius: 1.5rem;
  padding: 1rem 3rem;
  box-shadow: var(--box-shadow-input);
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  font-size: 1.3rem;
  line-height: 1;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}

.modal__button--cancel:hover {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
}

.modal__text {
  color: var(--light-dark);
  font-size: 1.2rem;
}

.modal__text span {
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.modalContainer__form--section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.modalContainer__form--section-2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.modalContainer__form--sections {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.modalContainer__form--deleteInput {
  display: flex;
  gap: 0.5rem;
  background-color: var(--white);
  padding: 1.5rem;
  padding-bottom: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.modal_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}

.modal_input--label {
  font-size: 1.2rem;
  color: var(--light-dark);
}

.modal_input--text {
  font-family: inherit;
  font-size: 1.3rem;
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  outline: none;
}

.modal_input--box {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  width: 30rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.modal_select-box {
  font-family: inherit;
  font-size: 1.3rem;
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}

.modal_input--iconBox {
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.modal_input--icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}

.modal_input--text2 {
  font-family: inherit;
  font-size: 1.3rem;
  outline: none;
  border: none;
  width: 100%;
}

.modal_input--text::-webkit-input-placeholder,
.modal_input--textarea::-webkit-input-placeholder,
.modal_input--text2::-webkit-input-placeholder {
  color: var(--placeholder);
}

.modal_text-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modal_input--textarea {
  width: 100%;
  min-height: 12rem;
  border: none;
  box-shadow: var(--box-shadow-input);
  font-family: inherit;
  font-size: 1.3rem;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  outline: none;
}

.modal_upload {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* align-items: flex-start; */
}

.modal_input__upload {
  width: 100%;
  height: 20rem;
  /* background-color: yellowgreen; */
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ManageClients__option--uploadContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ManageClients__option--upload {
  height: 2rem;
  width: 2rem;
  fill: var(--dark-color);
}

.preview_image {
  width: 100%;
  height: 100%;
}

.modal_input__upload--icon {
  height: 3.5rem;
  width: 3.5rem;
}

.modal__radioButtons,
.modal__radioButton {
  display: flex;
  align-items: center;
}

.modal__radioButtons {
  gap: 1rem;
}

.modal__radioButton {
  gap: 0.5rem;
  position: relative;
}

.modal__checkbox {
  gap: 0.5rem;
  position: relative;
}

.modal__radioButton--label {
  font-size: 1.2rem;
  font-weight: 600;
  /* padding-left: 35px; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal__radioButton--input {
  position: absolute;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}

.modal__checkbox--input {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}

.modal__radioButton--radio {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid var(--light-dark);
}

.modal__radioButton--radio:after {
  content: '';
  position: absolute;
  display: none;
}

.modal__radioButton--input:checked ~ .modal__radioButton--radio {
  border: 3px solid var(--primary-color);
}

.modal__radioButton--input:checked ~ .modal__radioButton--radio:after {
  display: block;
}

.modal__radioButton--radio:after {
  top: 0.5rem;
  left: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.modal_dropdown--box {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.brand__icon {
  height: 3rem;
  width: 4rem;
  fill: #e7e7ea;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .modalContainer {
    overflow: scroll;
  }

  .modal {
    width: 90%;
    height: 50vh;
    overflow: auto;
  }

  .modalContainer__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modalContainer__form--section {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0 1.5rem 0;
    margin-bottom: 1rem;
  }

  .modalContainer__form--sections {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: column;
  }

  .modalContainer__form--section-2 {
    gap: 1rem;
    padding: 1.5rem 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
  }

  .input__uploadContainer {
    display: flex;
    flex-direction: column;
  }

  .modal_input {
    align-items: center;
  }
}
