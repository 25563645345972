.filter {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
    transition: all 0.5s ease;
    overflow: hidden;
}

.filter__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter__heading--btn {
    border: none;
    background-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.5rem;
    border-bottom: 1px solid var(--primary-color);
    font-family: inherit;
    font-weight: 500;
    transition: all 0.4s;
}

.filter__heading--btn:hover {
    color: var(--dark-color);
    border-bottom: 1px solid var(--dark-color);
}

.filter__inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    /* align-items: center; */
}

.filter__heading {
    display: flex;
    /* margin-right: 1rem; */
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
}

.filter__heading2 {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
}

.filter__input--labels {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.filter__input--label {
    font-size: 1.2rem;
    color: var(--light-dark);
}

.filter__input--top {
    width: 15rem;
    /* background-color: var(--bg-primary); */
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
}

.filter__input {
    background-color: var(--bg-primary);
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.filter__input--select {
    border: none;
    font-family: inherit;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.filter__content {
    width: 100%;
    overflow: scroll;
    background-color: var(--white);
    gap: 2rem;
    border-radius: 1rem;
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.filter__content2 {
    background-color: var(--white);
    border-radius: 1rem;
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

/* .filter__content--top {
 background-color: seagreen;
 display: flex;
 gap: 1rem;
 align-items: flex-end;
} */
.filter__content--bottom {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
}

.filter__input--text {
    border: none;
    font-family: inherit;
    font-size: 1.2rem;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.filter__input--text::-webkit-input-placeholder {
    color: var(--placeholder);
}

.filter__input--iconBox {
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.filter__input--icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--white);
}

.filter__input--buttonContainer {
    gap: 2rem;
    display: flex;
    align-items: flex-end;
}

.filter__input--button {
    border: none;
    font-family: inherit;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    color: var(--white);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s;
}

.filter__input--button:hover {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.filter__input--button:disabled {
    background-color: transparent !important;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

@media (max-width: 1024px) {
    .filter {
        width: 100%;
        margin-top: 1rem;
    }

    .filter__inputs {
        padding: 0 3rem 0 3rem;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .filter {
        width: 100%;
        margin-top: 0;
    }

    .filter__inputs {
        margin-left: 0;
        width: 100%;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .filter__input--top {
        width: 10rem;
    }
}