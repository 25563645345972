.profile {
    /*display: flex;
 gap: 3rem;
 /* align-items: stretch; */
    display: grid;
    gap: 1rem;
    grid-template-columns: 60% 25%;
    grid-template-rows: 1fr 6rem;
}

.profile__personal {
    grid-column: 1/2;
    grid-row: 1/2;
}

.profile__heading {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
}

.filter__input--labels {
    position: static;
}

.profile__content {
    background-color: var(--white);
    border-radius: 1rem;
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.profile__name {
    display: flex;
    gap: 2rem;
}

.profile__content2 {
    grid-column: 1/2;
    grid-row: 2/-1;
    background-color: var(--white);
    border-radius: 1rem;
    padding: 1.5rem 3rem;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
}

.profile__password {
    grid-column: 2/-1;
    grid-row: 1/-1;
}

.profile__input {
    background-color: var(--bg-primary);
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow-input);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.profile__button {
    margin-top: 2rem;
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
    background-color: var(--primary-color);
    font-family: inherit;
    font-size: 1.3rem;
    align-self: flex-start;
    padding: 0.5rem 2rem;
    color: var(--white);
    box-shadow: var(--box-shadow-input);
    cursor: pointer;
    transition: all 0.4s;
}

.profile__button:hover {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
}

.profile__button--password {
    margin-top: 2rem;
    border: 1px solid var(--red);
    border-radius: 1rem;
    background-color: var(--red);
    font-family: inherit;
    font-size: 1.3rem;
    align-self: flex-start;
    padding: 0.5rem 2rem;
    color: var(--white);
    box-shadow: var(--box-shadow-input);
    cursor: pointer;
    transition: all 0.4s;
}

.profile__button--password:hover {
    border: 1px solid var(--red);
    background-color: transparent;
    color: var(--red);
}

@media (max-width: 900px) {
    .profile {
        /* margin-right: 2rem; */
        grid-template-columns: 90%;
        grid-template-rows: repeat(2, 1fr) 10rem;
        justify-content: center;
    }

    .profile__personal {
        grid-column: 1/-1;
        grid-row: 1/2;
    }

    .profile__password {
        grid-column: 1/-1;
        grid-row: 2/3;
    }

    .profile__content {
        width: 80%;
        flex-direction: column;
    }

    .profile__content2 {
        grid-column: 1/-1;
        grid-row: 3/-1;
    }

    .filter__input--labels {
        width: 100%;
    }

    .flex {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .filter__input {
        width: 100%;
    }

    .patient_profile__content {
        width: 100% !important;
    }


}

.patient_profile__content .modal_input {
    width: 100%;
}


.patient_profile__content
.email-input{
    width: 100% !important;
}

.patient_profile__content .modal_container {
    gap: 2rem;
    display: flex;
    justify-content: flex-start;
}



@media (max-width: 768px) {
    .patient_profile__content {
        width: 100% !important;
    }

    .patient_profile__content
    .email-input{
        width: 30vh !important;
    }

    .patient_profile__content .modal_input {
        width: 100%;
        align-items: start;

    }

    .patient_profile__content .modal_input--box {
        width: 30vh;
    }

    .patient_profile__content .modal_container {
        gap: 2rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .patient_profile__content 
    .cellphoneContainer{
        align-items: start !important;
    }
}