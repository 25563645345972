.verifyEmail__Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    height: 100vh;
}

.rightBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    color: var(--white);
    background-color: var(--primary-color);
    height: 100vh;
    width: 50%;
    position: absolute;
    z-index: -1;
}

.verifyEmail__Container h1 {
    font-size: 22px;
}

.verifyEmail__Container p {
    font-size: 16px;
    text-align: center;
}

.verifyEmail__Button {
    font-size: 16px;
}