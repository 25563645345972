.appContainer{
    background-image: linear-gradient(rgb(30, 148, 250), rgb(45, 222, 255));
    color: rgb(255, 255, 255);
    height: 872px;
}

.appContainer__box{
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.app_box{
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column; 
    padding: 60px 80px 30px;
    background: #fff ;
    height: auto;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16);
}

.app_image{
    width: 200px;
    height: auto;
    border: 0;
    margin: 0 0 15px;
    padding: 0;
}


.appavatar {
    width: 120px;
    height: 120px;
    padding: 0;
    border: 0;
    margin: 0 0 28px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
}

.anchortag{

}

.appContainer__box_logo{
    padding: 5% 0px; 
}


@media (max-width: 990px) {

    .app_box{
        background: transparent;
        box-shadow:none;
        padding:0px;
    }
    .appContainer__box_logo{ 
        width: 250px;
    }
    .appContainer__box_logo{
        padding: 15% 0px; 
    }
    
}