.pdmpMain {
 display: grid;
 grid-template-columns: 5rem repeat(2, 1fr) 5rem;
 height: 100%;
 align-items: center;

 width: 100%;
}
/* .pdmp__bg {
 background-color: var(--primary-color);
 height: 100%;
 width: 100%;
} */
.pdmpContainer__box {
 grid-column: 2 / -2;
 height: 80vh;
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 gap: 2rem;
 box-shadow: var(--box-shadow1);
 border-radius: 1rem;
 overflow: hidden;
}
.pdmpContainer__box--right {
 background-color: var(--primary-color);
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 color: var(--white);
}
.pdmpContainer__subheading {
 width: 60%;
 font-size: 1.5rem;
 font-weight: 400;
 text-align: center;
 line-height: 1.5;
}
.pdmpContainer__form {
 width: 80%;
 display: flex;
 flex-direction: column;
 gap: 1rem;
}
